import {
  AgendamentoConsultaAtendimentoInput,
  AgendamentoConsultaCompartilhadaInput,
  SalvarCuidadoCompartilhadoInput,
} from 'graphql/types.generated'
import {
  AgendarConsultaCuidadoCompartilhadoFieldsModel,
  CondutaGroupEnum,
  CuidadoCompartilhadoLotacaoModel,
  CuidadoCompartilhadoModel,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoFormModel,
  DiscussaoCasoSolicitanteFieldsModel,
  TipoAgendamentoCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import {
  getLotacoesConsultaCompartilhadaCuidadoCompartilhado,
  getResponsabilidadeCuidadoCompartilhado,
  isReclassificandoCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

const convertAgendamentoToInput = (
  values: AgendarConsultaCuidadoCompartilhadoFieldsModel['consultaComCidadao'],
  lotacao: CuidadoCompartilhadoLotacaoModel
): AgendamentoConsultaAtendimentoInput => ({
  lotacao: lotacao.id,
  horario: Number(values.horario.inicial),
  observacoes: values.observacoes,
  isForaUbs: false,
})

const convertConsultaCompartilhadaToInput = (
  values: AgendarConsultaCuidadoCompartilhadoFieldsModel['consultaCompartilhada'],
  lotacaoResponsavel: CuidadoCompartilhadoLotacaoModel,
  lotacaoConvidada: CuidadoCompartilhadoLotacaoModel,
  cidadaoId: ID,
  videochamadaUuid: string
): AgendamentoConsultaCompartilhadaInput => {
  return {
    horarioFinal: Number(values.horario.final),
    horarioInicial: Number(values.horario.inicial),
    observacoes: values.observacoes,
    lotacaoId: lotacaoResponsavel.id,
    lotacaoParticipanteId: lotacaoConvidada.id,
    nomeProfParticipante: lotacaoConvidada.profissional.nome,
    emailProfParticipante: lotacaoConvidada.profissional.email,
    telefoneProfParticipante: lotacaoConvidada.profissional.telefone,
    cidadao: values.isCidadaoPresente ? cidadaoId : null,
    videochamadaUuid,
  }
}

const convertAgendarConsulta = (
  values: DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel,
  cuidadoCompartilhado: CuidadoCompartilhadoModel,
  lotacaoId: ID,
  videochamadaUuid: string
) => {
  const fieldInput: Partial<SalvarCuidadoCompartilhadoInput> = {}

  const { lotacaoResponsavel, lotacaoConvidada } = getLotacoesConsultaCompartilhadaCuidadoCompartilhado(
    lotacaoId,
    cuidadoCompartilhado
  )

  if (values.agendamento.tipoAgendamento === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO) {
    fieldInput['agendamento'] = convertAgendamentoToInput(values.agendamento.consultaComCidadao, lotacaoResponsavel)
  } else {
    fieldInput['interconsulta'] = convertConsultaCompartilhadaToInput(
      values.agendamento.consultaCompartilhada,
      lotacaoResponsavel,
      lotacaoConvidada,
      cuidadoCompartilhado.cidadao.id,
      videochamadaUuid
    )
  }

  return fieldInput
}

const convertExecutanteFieldsToFormInput = (
  values: DiscussaoCasoExecutanteFieldsModel,
  cuidadoCompartilhado: CuidadoCompartilhadoModel,
  lotacaoId: ID,
  videochamadaUuid: string
): Omit<SalvarCuidadoCompartilhadoInput, 'cuidadoCompartilhadoId'> => {
  let fieldInput = {}

  switch (values?.condutaGroup) {
    case CondutaGroupEnum.DEVOLUTIVA_DISCUSSAO_CASO: {
      fieldInput['devolutiva'] = { ...values.devolutiva }
      break
    }
    case CondutaGroupEnum.TROCA_PROFISSIONAL_REFERENCIA: {
      fieldInput['trocaExecutante'] = {
        lotacaoExecutanteId: values.trocaExecutante.lotacao.id,
        motivo: values.trocaExecutante.motivo,
      }
      break
    }
    case CondutaGroupEnum.AGENDAR_CONSULTA: {
      fieldInput = convertAgendarConsulta(values, cuidadoCompartilhado, lotacaoId, videochamadaUuid)
      break
    }
    case CondutaGroupEnum.SUGESTAO_AGENDAMENTO_GRUPO: {
      fieldInput['sugestaoAgendamentoGrupo'] = { ...values.sugestaoAgendamentoGrupo }
      break
    }
    default: {
      break
    }
  }

  return fieldInput
}

const convertSolicitanteFieldsToFormInput = (
  values: DiscussaoCasoSolicitanteFieldsModel,
  cuidadoCompartilhado: CuidadoCompartilhadoModel,
  lotacaoId: ID,
  videochamadaUuid: string
): Omit<SalvarCuidadoCompartilhadoInput, 'cuidadoCompartilhadoId'> => {
  let fieldInput = {}

  switch (values?.condutaGroup) {
    case CondutaGroupEnum.NOVA_PERGUNTA: {
      fieldInput['pergunta'] = { ...values.pergunta }
      break
    }
    case CondutaGroupEnum.TROCA_PROFISSIONAL_REFERENCIA: {
      fieldInput['trocaExecutante'] = {
        lotacaoExecutanteId: values.trocaExecutante.lotacao.id,
        motivo: values.trocaExecutante.motivo,
      }
      break
    }
    case CondutaGroupEnum.AGENDAR_CONSULTA: {
      fieldInput = convertAgendarConsulta(values, cuidadoCompartilhado, lotacaoId, videochamadaUuid)
      break
    }
    default: {
      break
    }
  }

  return fieldInput
}

export const convertDiscussaoCasoFormModelToSalvarCuidadoCompartilhadoInput = (
  values: DiscussaoCasoFormModel,
  cuidadoCompartilhado: CuidadoCompartilhadoModel,
  lotacaoId: ID,
  videochamadaUuid: string
): SalvarCuidadoCompartilhadoInput => {
  const responsabilidade = getResponsabilidadeCuidadoCompartilhado(
    lotacaoId,
    cuidadoCompartilhado.lotacaoExecutanteAtual.id,
    cuidadoCompartilhado.lotacaoSolicitante.id
  )

  const isReclassificando = isReclassificandoCuidadoCompartilhado(
    cuidadoCompartilhado.classificacaoPrioridadeAtual,
    values.reclassificaoPrioridade
  )

  return {
    cuidadoCompartilhadoId: cuidadoCompartilhado.id,
    reclassificacaoPrioridade: isReclassificando ? values.reclassificaoPrioridade : null,
    ...(responsabilidade === 'EXECUTANTE'
      ? convertExecutanteFieldsToFormInput(values.executanteFields, cuidadoCompartilhado, lotacaoId, videochamadaUuid)
      : convertSolicitanteFieldsToFormInput(
          values.solicitanteFields,
          cuidadoCompartilhado,
          lotacaoId,
          videochamadaUuid
        )),
  }
}
