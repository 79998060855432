import { Alert, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Action, CuidadosCompartilhadoQueryInput } from 'graphql/types.generated'
import React from 'react'

import {
  CuidadoCompartilhadoItemActions,
  CuidadoCompartilhadoSort,
  CuidadoCompartilhadoSortEnum,
  ListaCuidadoCompartilhadoFilterModel,
} from '../model-cuidadoCompartilhado'
import { ListaCuidadoCompartilhadoView } from './ListaCuidadoCompartilhadoView'

const filterToQueryInput = (
  acessoId: ID,
  value: ListaCuidadoCompartilhadoFilterModel
): CuidadosCompartilhadoQueryInput => {
  const hasSolicitante = value.profissionalSolicitante?.profissional?.id
  return {
    query: value.query,
    status: value.status,
    dataInicio: value.periodo?.startDate,
    dataFim: value.periodo?.endDate,
    solicitadosPelaMinhaEquipe: hasSolicitante ? false : !value.somenteOsMeus,
    solicitadosPorProfissionalId: hasSolicitante ?? (value.somenteOsMeus ? acessoId : null),
    executadosPorProfissionalId: value.profissionalExecutante?.profissional?.id,
    pageParams: { ...value.pageParams, sort: CuidadoCompartilhadoSort[value.pageParams.sort] },
  }
}

const resolveDiscutirAction = (actions: CuidadoCompartilhadoItemActions): Action => actions.discutirCasoSolicitante

export const ListaCuidadoCompartilhadoSolicitanteView = () => {
  const { profissional } = useAcessoLotacaoOrEstagio()

  const filterToQuery = (value: ListaCuidadoCompartilhadoFilterModel) => filterToQueryInput(profissional?.id, value)

  return (
    <VFlow vSpacing={0.5}>
      <Alert type='info' inline>
        A lista apresenta todos os cuidados solicitados por você, além dos cuidados solicitados pela sua equipe.
      </Alert>
      <ListaCuidadoCompartilhadoView
        defaultSort={CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE}
        itemsToSort={[
          CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE,
          CuidadoCompartilhadoSortEnum.STATUS_SOLICITANTE,
        ]}
        filterSomenteOsMeusLabel='Ver somente as minhas solicitações'
        filterToQueryInput={filterToQuery}
        resolveDiscutirAction={resolveDiscutirAction}
      />
    </VFlow>
  )
}
