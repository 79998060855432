import useSession from 'components/auth/useSession'
import { isLotacaoOrEstagio } from 'components/auth/useSessionUtils'
import { useErrorHandler } from 'components/error'
import { LoadingIndicator } from 'components/loading'
import { information } from 'components/modals/information'
import { useVideochamadaViewQuery } from 'graphql/hooks.generated'
import { StatusVideochamadaEnum } from 'graphql/types.generated'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'
import { capitalize } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

import { CriarVideochamadaView } from './CriarVideochamadaView'
import { useSolicitarEntradaVideochamada } from './hooks/useSolicitarEntradaVideochamada'
import { useVideochamadaStatus } from './hooks/useVideochamadaStatus'
import {
  LocalVideocallParticipant,
  MotivoEncerramentoVideochamadaEnum,
  VideochamadaUrlParams,
} from './model-videochamada'
import { SalaEsperaVideochamadaUsuariosExternosView } from './SalaEsperaVideochamadaUsuariosExternosView'
import { VideochamadaCanceladaView } from './VideochamadaCanceladaView'
import { VideochamadaEncerradaView } from './VideochamadaEncerradaView'
import { VideochamadaView } from './VideochamadaView'

export interface VideochamadaQueryParams {
  audio?: string
  video?: string
  cidadaoPresente?: string
}

export function VideochamadaRootView() {
  const session = useSession()
  const history = useHistory()
  const handleRejection = useErrorHandler()

  const queryParams = useUrlQueryParams<VideochamadaQueryParams>()

  const {
    params: { uuid: videochamadaUuid },
  } = useRouteMatch<VideochamadaUrlParams>()

  const {
    loading,
    data: { videochamada },
  } = useVideochamadaViewQuery({ variables: { uuid: videochamadaUuid } })

  const idAcesso = session?.data?.acesso?.id
  const owner = videochamada?.owner

  const isOwner = !isUndefinedOrNull(idAcesso) && idAcesso === owner?.id
  const ownerName = owner?.profissional.nome
  const ownerCpf = owner?.profissional.cpf
  const cboProfissional =
    videochamada && owner ? (isLotacaoOrEstagio(owner) ? capitalize(owner?.cbo.nome) : null) : null

  const [selfData, setSelfData] = useState<LocalVideocallParticipant>()
  const [audioEnabled, setAudioEnabled] = useState(queryParams.audio !== 'false')
  const [videoEnabled, setVideoEnabled] = useState(queryParams.video !== 'false')

  const { solicitarEntradaVideochamada } = useSolicitarEntradaVideochamada({
    videochamadaUuid,
    onEntrar: setSelfData,
    isOwner,
  })

  const { status, motivoEncerramento } = useVideochamadaStatus(videochamadaUuid, true)

  useEffect(() => {
    if (status === StatusVideochamadaEnum.ENCERRADA) {
      switch (motivoEncerramento) {
        case MotivoEncerramentoVideochamadaEnum.ENCERRADA_POR_CRIADOR:
          history.push(`/videochamada/encerrada?owner=${isOwner}`)
          break
        case MotivoEncerramentoVideochamadaEnum.TIMEOUT:
          history.push('/videochamada/timeout')
          break
        case MotivoEncerramentoVideochamadaEnum.CONEXAO_FALHOU:
          showConnectionFailModal(isOwner, () => history.push(`/videochamada/encerrada?owner=${isOwner}`))
          break
        default:
          history.push('/videochamada/encerrada')
          break
      }
    }
  }, [history, isOwner, motivoEncerramento, status])

  // Se é o owner e a videochamada já foi iniciada, entra sem passar pela sala de espera
  useEffect(() => {
    if (isOwner && !selfData && status === StatusVideochamadaEnum.ATIVA)
      solicitarEntradaVideochamada(ownerName, ownerCpf, true).catch(handleRejection)
  }, [solicitarEntradaVideochamada, isOwner, ownerCpf, ownerName, selfData, handleRejection, status])

  return loading || (isOwner && !selfData && status === StatusVideochamadaEnum.ATIVA) ? (
    <LoadingIndicator />
  ) : status === StatusVideochamadaEnum.CANCELADA ? (
    <VideochamadaCanceladaView />
  ) : status === StatusVideochamadaEnum.AGUARDANDO_INICIO && isOwner ? (
    <CriarVideochamadaView horarioInicioVideochamada={videochamada?.agendamento?.horarioInicial} />
  ) : status === StatusVideochamadaEnum.ENCERRADA ? (
    <VideochamadaEncerradaView />
  ) : selfData ? (
    <VideochamadaView
      selfData={selfData}
      isOwner={isOwner}
      videochamadaId={videochamadaUuid}
      audioEnabled={audioEnabled}
      videoEnabled={videoEnabled}
      setAudioEnabled={setAudioEnabled}
      setVideoEnabled={setVideoEnabled}
    />
  ) : (
    <SalaEsperaVideochamadaUsuariosExternosView
      videochamadaUuid={videochamadaUuid}
      nomeProfissional={ownerName}
      cboProfissional={cboProfissional}
      onEntrar={setSelfData}
      audioEnabled={audioEnabled}
      videoEnabled={videoEnabled}
      setAudioEnabled={setAudioEnabled}
      setVideoEnabled={setVideoEnabled}
      statusVideochamada={status}
    />
  )
}

const showConnectionFailModal = (isOwner: boolean, onClose: () => void) =>
  information({
    title: 'Conexão falhou',
    body:
      'Não foi possível estabelecer uma conexão com o outro participante. Verifique suas configurações ou entre em contato com o administrador da rede.',
    iconColor: 'alert',
    showCloseButton: true,
    closeLabel: isOwner ? 'Encerrar chamada' : 'Voltar',
    onClose,
  })()
