import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { useAgendaAuthorizationQuery } from 'graphql/hooks.generated'
import { useCallback, useMemo } from 'react'
import Permissions from 'types/Permissions'

import { LotacaoAgendaModel } from '../model-agenda'

export function useAgendaAuthorization(isAd: boolean) {
  const {
    data: {
      profissional: { id: profissionalAcessoId },
    },
  } = useSession({ fetchPolicy: 'cache-first' })

  const { hasAuthorization, acesso } = useAcessoLotacaoOrEstagio()

  const equipeId = acesso?.equipe?.id

  const {
    data: { equipe: { emapsApoio: emapsQueApoiamEquipeAcesso } = {} },
  } = useAgendaAuthorizationQuery({
    variables: { equipeId },
    skip: !isAd || !equipeId,
  })

  const idsEquipesPodeAlterar = useMemo(
    () => new Set<ID>([equipeId, ...(emapsQueApoiamEquipeAcesso?.map((e) => e.id) ?? [])]),
    [equipeId, emapsQueApoiamEquipeAcesso]
  )

  const hasAuthorizationAgendar = useCallback(
    ({ equipe, profissional }: LotacaoAgendaModel) =>
      hasAuthorization(Permissions.visualizarAgenda.agendar) &&
      (profissional.id === profissionalAcessoId ||
        !equipe ||
        equipeId === equipe?.id ||
        hasAuthorization(Permissions.visualizarAgenda.agendar.todasAsEquipes)),
    [equipeId, hasAuthorization, profissionalAcessoId]
  )

  const hasAuthorizationAgendarAd = useCallback(
    ({ equipe }: LotacaoAgendaModel) =>
      hasAuthorization(
        Permissions.atencaoDomiciliar.visualizarAgendaDeAdDaEquipe.visualizarAgendaDeAdDoMunicipio.agendar
      ) ||
      (idsEquipesPodeAlterar.has(equipe?.id) &&
        hasAuthorization(Permissions.atencaoDomiciliar.visualizarAgendaDeAdDaEquipe.agendar)),
    [hasAuthorization, idsEquipesPodeAlterar]
  )

  const hasAuthorizationCancelar = useCallback(
    ({ equipe, profissional }: LotacaoAgendaModel) =>
      hasAuthorization(Permissions.visualizarAgenda.cancelar) &&
      (profissional.id === profissionalAcessoId ||
        !equipe ||
        equipeId === equipe.id ||
        hasAuthorization(Permissions.visualizarAgenda.cancelar.todasAsEquipes)),
    [equipeId, hasAuthorization, profissionalAcessoId]
  )

  const hasAuthorizationCancelarAd = useCallback(
    ({ equipe }: LotacaoAgendaModel) =>
      hasAuthorization(
        Permissions.atencaoDomiciliar.visualizarAgendaDeAdDaEquipe.visualizarAgendaDeAdDoMunicipio.cancelar
      ) ||
      (idsEquipesPodeAlterar.has(equipe?.id) &&
        hasAuthorization(Permissions.atencaoDomiciliar.visualizarAgendaDeAdDaEquipe.cancelar)),
    [hasAuthorization, idsEquipesPodeAlterar]
  )

  return useMemo(
    () => ({
      hasAuthorizationAgendar: isAd ? hasAuthorizationAgendarAd : hasAuthorizationAgendar,
      hasAuthorizationCancelar: isAd ? hasAuthorizationCancelarAd : hasAuthorizationCancelar,
    }),
    [hasAuthorizationAgendar, hasAuthorizationAgendarAd, hasAuthorizationCancelar, hasAuthorizationCancelarAd, isAd]
  )
}
