/** @jsx jsx * */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, HFlow, Spinner, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import theme from 'config/theme'
import { useFlags } from 'config/useFlagsContext'
import { useCidadaoInformacoesContatoQuery, usePrescricaoDigitalEnabledQuery } from 'graphql/hooks.generated'
import { memo, useEffect, useState } from 'react'
import { isEmpty } from 'util/validation/Util'

import { EmailUpdateForm } from '../../components/EmailUpdateForm'
import { AtestadoAssinaturaDigitalGerarCheckbox } from './AtestadoAssinaturaDigitalGerarCheckbox'

interface AtestadoAssinaturaDigitalViewProps {
  cidadaoId: ID
  onEmailCidadaoChange: (disable: boolean) => void
  hasCid10?: boolean
  style?: ExternalStyles
  onDirtyChange: (dirty: boolean) => void
  canceled?: boolean
  onCancel?: (canceled: boolean) => void
}

export const AtestadoAssinaturaDigitalView = memo((props: AtestadoAssinaturaDigitalViewProps) => {
  const {
    cidadaoId,
    onEmailCidadaoChange,
    hasCid10 = false,
    style = styles.externalStylesDefault,
    onDirtyChange,
    canceled = false,
    onCancel,
  } = props

  const { data: infoCidadao } = useCidadaoInformacoesContatoQuery({
    variables: { id: cidadaoId },
  })

  const [isAtestadoDigitalChecked, setAtestadoDigitalChecked] = useState<boolean>(false)
  const alert = useAlert()
  const cidadaoEmail = infoCidadao?.contatoCidadao?.email
  const isCidadaoEmailEmpty = isEmpty(cidadaoEmail)
  const { PRESCRICAO_DIGITAL_ETAPA_TRES_ENABLED } = useFlags()
  const { data: infoPrescricaoDigital, loading } = usePrescricaoDigitalEnabledQuery()

  useEffect(() => {
    onEmailCidadaoChange(isAtestadoDigitalChecked && isCidadaoEmailEmpty && PRESCRICAO_DIGITAL_ETAPA_TRES_ENABLED)

    if (hasCid10) {
      setAtestadoDigitalChecked(false)
    }
  }, [
    PRESCRICAO_DIGITAL_ETAPA_TRES_ENABLED,
    hasCid10,
    isAtestadoDigitalChecked,
    isCidadaoEmailEmpty,
    onEmailCidadaoChange,
  ])

  if (PRESCRICAO_DIGITAL_ETAPA_TRES_ENABLED && loading) {
    return (
      <HFlow style={styles.loadingContainer}>
        <Spinner />
        <Text color='primary' fontWeight='bold'>
          Carregando e-mail
        </Text>
      </HFlow>
    )
  }

  if (!PRESCRICAO_DIGITAL_ETAPA_TRES_ENABLED || !infoPrescricaoDigital?.info?.isPrescricaoDigitalEnabled) {
    return null
  }

  const handleEmailChange = () => alert('success', 'E-mail atualizado com sucesso.')

  return (
    <HFlow style={[styles.container, style]}>
      <AtestadoAssinaturaDigitalGerarCheckbox
        checked={isAtestadoDigitalChecked}
        onChange={setAtestadoDigitalChecked}
        hasCid10={hasCid10}
      />
      <EmailUpdateForm
        cidadaoId={cidadaoId}
        cidadaoEmail={cidadaoEmail}
        onSubmitSuccess={handleEmailChange}
        disabled={!isAtestadoDigitalChecked}
        initialValues={{ email: cidadaoEmail }}
        onDirtyChange={onDirtyChange}
        canceled={canceled}
        onCancel={onCancel}
      />
    </HFlow>
  )
})

const styles = {
  container: css`
    width: 100%;
  `,
  externalStylesDefault: css`
    padding-left: 0rem;
  `,
  loadingContainer: css`
    padding-left: 0.5rem;
    color: ${theme.pallete.primary.main};
    width: 100%;
    display: flex;
    grid-gap: 0.5rem;
  `,
}
