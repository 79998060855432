import axios, { AxiosPromise } from 'axios'
import {
  AtestadoImpressaoInput,
  AtestadoRecenteImpressaoInput,
} from 'view/atendimentos/detail/soap/plano/atestados/model-atestado'

export class Atestado {
  imprimirAtestado(input: AtestadoImpressaoInput): AxiosPromise<Blob> {
    return axios.post('/api/atestado/imprimirAtestado', input, { responseType: 'blob' })
  }

  imprimirAtestadoRecente(input: AtestadoRecenteImpressaoInput): AxiosPromise<Blob> {
    return axios.post('/api/atestado/imprimirAtestadoRecente', input, { responseType: 'blob' })
  }
}
