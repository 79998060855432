/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Text } from 'bold-ui'
import { TelefoneField, TextField } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { MetaPath } from 'util/metaPath'

import { ContatoCidadaoAgendamento } from '../model-agenda'

interface ContatoCidadaoAgendamentoFieldProps {
  name: MetaPath<ContatoCidadaoAgendamento>
  disabled: boolean
  telefoneCelularRequired: boolean
  emailRequired: boolean
}

export const ContatoCidadaoAgendamentoField = (props: ContatoCidadaoAgendamentoFieldProps) => {
  const { name, disabled, telefoneCelularRequired: telefoneRequired, emailRequired } = props

  return (
    <Grid>
      <Cell size={12}>
        <Text fontWeight='bold'>Dados de contato do cidadão</Text>
        <InfoIcon
          icon='infoCircleFilled'
          text='Verifique os dados de contato do cidadão durante o agendamento.'
          style={styles.infoIcon}
        />
      </Cell>
      <Cell size={6}>
        <TextField
          lowercase
          name={name.email}
          label='E-mail'
          maxLength={50}
          required={emailRequired}
          disabled={disabled}
        />
      </Cell>
      <Cell size={6}>
        <TelefoneField
          name={name.telefoneCelular}
          label='Telefone celular'
          required={telefoneRequired}
          disabled={disabled}
          // Foi definido assim pois a máscara só funciona com o campo 'sujo'
          initialValue='0000000000'
        />
      </Cell>
    </Grid>
  )
}

const styles = {
  infoIcon: css`
    padding: 0.2rem 0 0 0.2rem;
  `,
}
