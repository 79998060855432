import api from 'api'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'

import { ImpressaoMedicamentosInput } from '../../impressao/ImpressaoMedicamentosInput'
import { PrescricaoDigitalErrorCodeEnum } from '../PrescricaoDigitalIntegracaoMessage'

const INTERVAL_DELAY = 500 // ms

type Status = 'success' | 'failure' | 'canceled'

interface GerarPrescricaoResponse {
  status?: Status
  response?: AxiosResponse
  codigoPrescricao?: string
  error?: PrescricaoDigitalErrorCodeEnum
}

interface GerarPrescricaoInput {
  atendimentoId: ID
  cidadaoId: ID
  emailCidadao: string
}

export function useGerarPrescricaoPopup(
  input: GerarPrescricaoInput
): {
  data: GerarPrescricaoResponse
  openPopup: (impressaoMedicamentosInput: ImpressaoMedicamentosInput) => Promise<void>
  closePopup: () => void
} {
  const [externalPopup, setExternalPopup] = useState<Window>(null)
  const [responseData, setResponseData] = useState<GerarPrescricaoResponse>(null)

  const openWindow = async (impressaoMedicamentosInput: ImpressaoMedicamentosInput) => {
    const response = await api.prescricaoDigital.gerarPrescricaoDigital(impressaoMedicamentosInput)
    const windowAssinatura = window.open(
      response.data,
      'prescricao-digital',
      `width=${window.screen.availWidth}, heigth=${window.screen.availHeight}`
    )

    setExternalPopup(windowAssinatura)
  }

  useEffect(() => {
    if (!externalPopup || externalPopup.closed) return

    const timer = setInterval(async () => {
      if (!externalPopup || externalPopup.closed) {
        timer && clearInterval(timer)
        if (!responseData?.status) {
          setResponseData({ status: 'canceled' })
        }
        return
      }

      let currentUrl: string

      try {
        currentUrl = externalPopup.location.href
      } catch {
        return
      }

      if (!currentUrl) return

      const searchParams = new URL(currentUrl).searchParams
      const codigoPrescricao = searchParams.get('prescricaoId')
      const error = searchParams.get('error')

      if (error) {
        timer && clearInterval(timer)
        setResponseData({ status: 'failure', error: (error as unknown) as PrescricaoDigitalErrorCodeEnum })
      } else if (codigoPrescricao) {
        timer && clearInterval(timer)

        try {
          const response = await api.prescricaoDigital.emitirPrescricaoDigital({
            ...input,
            codigoPrescricao: codigoPrescricao,
          })
          if (response) {
            setResponseData({
              status: 'success',
              response: response,
              codigoPrescricao: codigoPrescricao,
            })
          }
        } catch {
          setResponseData({ status: 'failure' })
        }
      }

      return () => {
        timer && clearInterval(timer)
      }
    }, INTERVAL_DELAY)
  }, [externalPopup, input, responseData])

  return {
    data: responseData,
    openPopup: openWindow,
    closePopup: () => externalPopup?.close(),
  }
}
