/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { focusBoxShadow, HFlow, Icon, Text, Theme, useTheme } from 'bold-ui'
import { turquoise } from 'bold-ui/lib/styles/colors'
import { Ellipsis } from 'components/Ellipsis'
import { format } from 'date-fns'
import { useMemo } from 'react'

import { AcoesAgendamentoReserva } from '../acoes-agendamento/AcoesAgendamentoReserva'
import { EventoAgendamentoProps } from './EventoAgendamento'
import { EventoAgendamentoPopperButton } from './EventoAgendamentoPopperButton'

const formatoHorario = 'HH:mm'

export function EventoAgendamentoReserva({ event, eventHasOverlap, isAgendaOnlineAtiva }: EventoAgendamentoProps) {
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, eventHasOverlap), [eventHasOverlap, theme])
  const motivoReserva = event.motivoReserva

  return (
    <div css={styles.container}>
      <EventoAgendamentoPopperButton
        isAgendaOnlineAtiva={isAgendaOnlineAtiva}
        event={event}
        style={styles.popperButton}
      >
        {eventHasOverlap ? (
          <Ellipsis title={motivoReserva}>
            <Text fontWeight='bold' style={styles.motivo}>
              {motivoReserva}
            </Text>
          </Ellipsis>
        ) : (
          <HFlow
            style={css`
              width: 100%;
              grid-template-columns: repeat(3, auto);
              justify-content: flex-start;
            `}
          >
            <Icon style={styles.iconeBookmark} size={1} icon='bookmarkFilled' />
            <div css={styles.internalContainer}>
              <Ellipsis title={motivoReserva + ' | Outros'} style={styles.motivo}>
                <Text fontWeight='bold'>{motivoReserva}</Text>
                {event.isOutroMotivoReserva && <Text style={styles.outros}>&nbsp;| Outros</Text>}
              </Ellipsis>

              <Ellipsis>
                <Text fontWeight='bold'>
                  {format(event.start, formatoHorario)} até {format(event.end, formatoHorario)}
                  {event.observacoes && ' | Observações: '}
                </Text>
                {event.observacoes}
              </Ellipsis>
            </div>
          </HFlow>
        )}
      </EventoAgendamentoPopperButton>
      <AcoesAgendamentoReserva event={event} />
    </div>
  )
}

const createStyles = (theme: Theme, eventHasOverlap: boolean) => ({
  container: css`
    height: calc(100% - 0.25rem);
    display: flex;
    width: 100%;
    padding-right: 1rem;
    background-color: ${turquoise.c90};
    border: 1px solid ${turquoise.c60};
    border-radius: 2px;
    margin: 0;
    min-height: 3rem;
    box-shadow: ${theme.shadows.outer[10]};
    &:focus-within {
      outline: none;
      box-shadow: ${focusBoxShadow(theme)};
    }
  `,

  internalContainer: css`
    padding: 0.25rem 0;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    span {
      white-space: nowrap;
    }
  `,

  motivo: css`
    color: ${turquoise.c40};
  `,

  outros: css`
    color: ${turquoise.c40};
    white-space: nowrap;
  `,

  iconeBookmark: css`
    margin-top: 0.25rem;
    height: 1.5rem;
    margin-right: -0.4rem;
    color: ${turquoise.c40};
  `,
  popperButton: eventHasOverlap
    ? css`
        padding-left: 1.5rem;
        overflow: hidden;
        padding-right: 0.75rem;
      `
    : css`
        padding: 0.25rem 0 0.25rem 1rem;
        width: 95%;
      `,
})
