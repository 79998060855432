/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Cell } from 'bold-ui'

export const VideochamadaNaoIniciadaAlertCell = () => (
  <Cell size={12} style={stylesAlert.container}>
    <Alert inline type='warning' styles={{ wrapper: stylesAlert.infoWrapper }}>
      A chamada ainda não começou
    </Alert>
  </Cell>
)

const stylesAlert = {
  container: css`
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: flex-end;
  `,
  infoWrapper: css`
    padding: 0;
    background: none;
    border: none;
    min-height: 0;
  `,
}
