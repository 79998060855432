import { useApolloClient } from '@apollo/react-hooks'
import { css } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import {
  CidadaoAtendimentoSelectField,
  EquipeSelectField,
  Form,
  FormRenderProps,
  SubmitButton,
  TextAreaField,
} from 'components/form'
import { TipoServicoSelectField } from 'components/form/field/select/TipoServicoSelectField'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { useAdicionarGarantiaAcessoMutation, useGarantiaAcessoCidadaoQuery } from 'graphql/hooks.generated'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import { metaPath } from 'util/metaPath'
import { resolveEquipeTooltip } from 'view/atendimentos/tooltipResolver'

import { createEquipeCalculations } from './calculator-garantiaAcesso'
import { TipoAtendimentoSelectField } from './components/TipoAtendimentoSelectField'
import { convertGarantiaAcessoFormModelToInput } from './convert-garantiaAcesso'
import { useGestaoGarantiaAcessoConflitantes } from './hooks/useGestaoGarantiaAcessoConflitantes'
import { GarantiaAcessoFormModel } from './model-garantiaAcesso'
import { garantiaAcessoValidator } from './validator-garantiaAcesso'

const path = metaPath<GarantiaAcessoFormModel>()

interface GarantiaAcessoFormProps {
  cidadaoId?: string
}

const callbackUrl = '/gestao-filas/garantia-acesso'

export default function GarantiaAcessoForm(props: GarantiaAcessoFormProps) {
  const { cidadaoId } = props
  const alert = useAlert()
  const apollo = useApolloClient()
  const history = useHistory()
  const { analytics } = useFirebase()
  const {
    acesso: { unidadeSaude },
    tipoEstabelecimento,
  } = useAcessoLotacaoOrEstagio()
  const unidadeSaudeId = unidadeSaude.id
  const [salvar] = useAdicionarGarantiaAcessoMutation()
  const { verificarRegistrosConflitantes } = useGestaoGarantiaAcessoConflitantes()
  const isCEO = tipoEstabelecimento === TipoEstabelecimentoEnum.CEO
  const {
    data: { cidadao },
  } = useGarantiaAcessoCidadaoQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id: cidadaoId,
    },
    skip: !cidadaoId,
  })

  const initialValues: GarantiaAcessoFormModel = useMemo(() => {
    if (cidadao?.faleceu === false) {
      return {
        cidadao: { ...cidadao, presenteListaAtendimento: false },
        equipe: { ...cidadao.cidadaoVinculacaoEquipe?.equipe },
      }
    }
  }, [cidadao])

  const resetFormFields = (formApi: FormApi) => {
    formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
    setTimeout(formApi.reset)
    history.replace(callbackUrl)
  }

  const save = (values: GarantiaAcessoFormModel, formApi: FormApi) =>
    salvar({ variables: { input: convertGarantiaAcessoFormModelToInput(values) } }).then(() => {
      analytics.logEvent('adicionar_registro_GGA', { tipo_de_estabelecimento: tipoEstabelecimento })
      alert('success', 'Cidadão foi adicionado com sucesso.')
      resetFormFields(formApi)
    })

  const handleSubmit = (values: GarantiaAcessoFormModel, formApi: FormApi) => {
    return verificarRegistrosConflitantes(unidadeSaude.id, cidadao || values.cidadao, isCEO, values).then(
      (confirmed) => confirmed && save(values, formApi)
    )
  }

  const renderForm = (formProps: FormRenderProps<GarantiaAcessoFormModel>) => {
    const { values } = formProps
    return (
      <Grid>
        <Cell size={5}>
          <VFlow>
            <CidadaoAtendimentoSelectField
              name={path.cidadao}
              label='Cidadão'
              title='Cidadão'
              ativo
              required
              placeholder='Buscar por Nome completo, CPF ou CNS'
              obito={false}
              addCidadaoCallbackUrl={callbackUrl}
              verificarPresenteNaListaAtendimento={false}
            />

            {!isCEO && (
              <TipoAtendimentoSelectField
                name={path.tipoAtendimento}
                label='Tipo de atendimento'
                title='Tipo de atendimento'
                required
              />
            )}

            <TextAreaField
              name={path.motivo}
              label='Motivo da consulta'
              title='Motivo da consulta'
              maxLength={200}
              required
              style={css`
                height: 5rem;
                resize: none;
              `}
            />
          </VFlow>
        </Cell>

        <Cell size={5}>
          <VFlow>
            {isCEO ? (
              <TipoServicoSelectField
                name={path.tipoServico}
                label='Tipo de serviço'
                title='Tipo de serviço'
                required
              />
            ) : (
              <Tooltip text={resolveEquipeTooltip(values)}>
                <EquipeSelectField name={path.equipe} label='Equipe' unidadeSaudeId={unidadeSaudeId} />
              </Tooltip>
            )}
          </VFlow>
        </Cell>

        <Cell alignSelf='flex-end' size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='medium' onClick={() => resetFormFields(formProps.form)}>
              Limpar campos
            </Button>
            <SubmitButton size='medium' handleSubmit={formProps.handleSubmit}>
              Adicionar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  const decorator = useMemo(() => createDecorator(...createEquipeCalculations(path, isCEO, unidadeSaudeId, apollo)), [
    isCEO,
    unidadeSaudeId,
    apollo,
  ])

  return (
    <Form<GarantiaAcessoFormModel>
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={garantiaAcessoValidator(isCEO)}
      decorators={[decorator]}
    />
  )
}
