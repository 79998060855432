/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import {
  CheckboxField,
  DateField,
  ErrorField,
  Form,
  FormRenderProps,
  HorarioAgendaSelectField,
  TelefoneField,
  TextAreaField,
  TextField,
} from 'components/form'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { addMonths, formatISO, parseISO } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import {
  useAtualizarContatoCidadaoMutation,
  useSalvarAgendamentoConsultaCompartilhadaMutation,
} from 'graphql/hooks.generated'
import React, { useMemo, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { useVideochamadasConfiguration } from 'view/videochamada/hooks/useVideochamadasConfiguration'

import { InserirVideochamadaAgendamentoSection } from '../components/InserirVideochamadaAgendamentoSection'
import {
  convertConsultaCompartilhadaModelToInput,
  convertConsultaModelToUpdateContatoCidadaoInput,
} from '../converter-agenda'
import { useVerificarAgendamentosConflitantes } from '../hooks/useVerificarAgendamentosConflitantes'
import { AgendamentoConsultaCompartilhadaModel, LotacaoAgendaModel } from '../model-agenda'
import { buildAgendamentosConflitantesErrorMessage } from '../util-agenda'
import { agendamentoConsultaCompartilhadaDecorator } from './calculator-agenda'
import { CidadaoAgendamentoCompartilhadoAccordion } from './CidadaoAgendamentoCompartilhadoAccordion'
import { agendamentoConsultaCompartilhadaValidator } from './validator-agenda'

export const meta = metaPath<AgendamentoConsultaCompartilhadaModel>()

export interface AgendamentoConsultaCompartilhadaFormProps {
  lotacao: LotacaoAgendaModel
  onSubmit?(): void
  onClose(): void
  getServerTimeNow(): Date
  start?: number
  end?: number
}

export const AgendamentoConsultaCompartilhadaForm = (props: AgendamentoConsultaCompartilhadaFormProps) => {
  const { lotacao, onSubmit, onClose, getServerTimeNow, start, end } = props

  const serverTime = getServerTimeNow()
  const alert = useAlert()

  const { loading: loadingConfiguracoesVideochamada, videochamadasEnabled } = useVideochamadasConfiguration()
  const [videochamadaUuid, setVideochamadaUuid] = useState<string>()

  const {
    acesso: { unidadeSaude },
  } = useAcessoLotacaoOrEstagio()

  const [salvar] = useSalvarAgendamentoConsultaCompartilhadaMutation()
  const [atualizarContatoCidadao] = useAtualizarContatoCidadaoMutation()

  const updateContato = (values: AgendamentoConsultaCompartilhadaModel) => {
    const contatoCidadao = values.cidadaoParticipante?.cidadao?.contato
    const contatoAtualizado = values.cidadaoParticipante?.contato
    const telefoneCelularAlterado = contatoCidadao?.telefoneCelular !== contatoAtualizado?.telefoneCelular
    const emailAlterado = contatoCidadao?.email !== contatoAtualizado?.email

    if (telefoneCelularAlterado || emailAlterado)
      return atualizarContatoCidadao({
        variables: {
          input: convertConsultaModelToUpdateContatoCidadaoInput(
            values.cidadaoParticipante.cidadao.id,
            contatoAtualizado
          ),
        },
      })
  }

  const save = (values: AgendamentoConsultaCompartilhadaModel) =>
    salvar({
      variables: { input: convertConsultaCompartilhadaModelToInput(values, lotacao.id, videochamadaUuid) },
    }).then(() => {
      alert('success', 'Agendamento de consulta compartilhada cadastrado com sucesso.')
      onSubmit?.()
      onClose()
    })

  const { verificarAgendamentosConflitantes } = useVerificarAgendamentosConflitantes()

  const handleFormSubmit = async (values: AgendamentoConsultaCompartilhadaModel) => {
    const identificadorAgendamentoConflitanteInput = [
      { lotacaoId: lotacao.id, profissionalId: lotacao.profissional.id, horario: Number(values.horario.inicial) },
    ]
    try {
      if (!!values.cidadaoParticipante?.cidadao?.id) {
        const confirmed = await verificarAgendamentosConflitantes(
          values.cidadaoParticipante.cidadao.id,
          identificadorAgendamentoConflitanteInput,
          values.cidadaoParticipante.cidadao.nomeSocial ?? values.cidadaoParticipante.cidadao.nome
        )

        if (confirmed) await updateContato(values)
        else return
      }

      return await save(values)
    } catch (apolloError) {
      // TODO@RNG #17670 Quando eh clicado em qualquer parte da tela o popper eh fechado e portanto nao temos mais o form, verificar se iremos trabalhar para conseguir manter o mesmo aberto para poder apresentar os erros em cada field
      const error = buildAgendamentosConflitantesErrorMessage(apolloError)
      alert('danger', error)
    }
  }

  const initialValues = useMemo(
    () => ({
      horario: { inicial: new Date(start), final: new Date(end) },
      data: start ? formatISO(start) : undefined,
    }),
    [end, start]
  )

  const renderForm = (formProps: FormRenderProps<Partial<AgendamentoConsultaCompartilhadaModel>>) => {
    const { handleSubmit, values } = formProps

    return (
      <React.Fragment>
        <div css={styles.box}>
          <Grid>
            <Cell size={12}>
              <ErrorField name={FORM_ERROR} inline={false} type='alert' />
            </Cell>
            <Cell size={3}>
              <DateField
                name={meta.data}
                label='Data'
                minDate={serverTime}
                maxDate={addMonths(serverTime, 12)}
                clearable
                required
              />
            </Cell>

            <Cell size={3}>
              <HorarioAgendaSelectField
                name={meta.horario}
                lotacaoId={lotacao.id}
                outraLotacaoIdVerificarDisponibilidade={values.lotacaoConvidada?.id}
                dia={values.data ? parseISO(values.data) : undefined}
                disabled={!values.data}
                labels={{ inicial: 'Horário' }}
                clearable
                required
              />
            </Cell>

            <Cell size={6}>
              {(videochamadasEnabled || loadingConfiguracoesVideochamada) && (
                <InserirVideochamadaAgendamentoSection
                  videochamadaUuid={videochamadaUuid}
                  onVideochamadaUuidChange={setVideochamadaUuid}
                  onRemoverVideochamadaClick={() => setVideochamadaUuid(null)}
                />
              )}
            </Cell>

            <Cell size={12}>
              <HFlow hSpacing={1}>
                <Text fontSize={0.875} fontWeight='bold'>
                  Dados do profissional convidado
                </Text>
                <CheckboxField label='Profissional externo' name={meta.isProfissionalExterno} />
              </HFlow>
            </Cell>

            {values.isProfissionalExterno ? (
              <React.Fragment>
                <Cell size={6}>
                  <TextField name={meta.nomeProfissionalExterno} label='Profissional' required />
                </Cell>
                <Cell size={6}>
                  <TextField name={meta.estabelecimentoSaudeProfExterno} label='Estabelecimento de saúde' />
                </Cell>

                <Cell
                  size={6}
                  style={css`
                    padding-bottom: 0;
                  `}
                >
                  <TextField lowercase name={meta.emailProfissionalExterno} label='E-mail' maxLength={50} />
                </Cell>
                <Cell
                  size={6}
                  style={css`
                    padding-bottom: 0;
                  `}
                >
                  <TelefoneField name={meta.telefoneProfissionalExterno} label='Telefone celular' />
                </Cell>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Cell size={6}>
                  <LotacaoAndEstagioSelectField
                    name={meta.lotacaoConvidada}
                    label='Profissional'
                    unidadeSaudeId={unidadeSaude.id}
                    excludeProfissionaisIds={[lotacao.profissional.id]}
                    possuiAgendaCadastrada
                    required
                  />
                </Cell>
                <Cell size={6} />

                <Cell
                  size={6}
                  style={css`
                    padding-bottom: 0;
                  `}
                >
                  <TextField
                    lowercase
                    name={meta.email}
                    label='E-mail'
                    maxLength={50}
                    disabled={!values.lotacaoConvidada || !!values.lotacaoConvidada?.profissional.email}
                  />
                </Cell>
                <Cell
                  size={6}
                  style={css`
                    padding-bottom: 0;
                  `}
                >
                  <TelefoneField
                    name={meta.telefone}
                    label='Telefone celular'
                    disabled={!values.lotacaoConvidada || !!values.lotacaoConvidada?.profissional.telefone}
                    // Foi definido assim pois a máscara só funciona com o campo 'sujo'
                    initialValue='0000000000'
                  />
                </Cell>
              </React.Fragment>
            )}
            <Cell
              size={12}
              style={css`
                padding-top: 0;
                padding-bottom: 0;
                margin-top: 0;
              `}
            >
              <CidadaoAgendamentoCompartilhadoAccordion
                title='Adicionar cidadão'
                id='adicionarCidadaoAgendamentoCompartilhadoAccordion'
                accordionName={meta.openedAccordions}
                name={meta.cidadaoParticipante}
                cidadao={values.cidadaoParticipante?.cidadao}
              />
            </Cell>
            <Cell
              size={12}
              style={css`
                padding-top: 0;
                padding-bottom: 0;
              `}
            >
              <TextAreaField label='Observações' name={meta.observacoes} maxLength={200} resize='none' />
            </Cell>
            <Cell size={4} />
          </Grid>
        </div>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' onClick={onClose} size='medium'>
            Cancelar
          </Button>
          <Button kind='primary' onClick={handleSubmit} size='medium'>
            Salvar
          </Button>
        </HFlow>
      </React.Fragment>
    )
  }

  return (
    <Form<Partial<AgendamentoConsultaCompartilhadaModel>>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleFormSubmit}
      suppressNotificationError //Previne mostrar alerta de validação fora do popup
      validate={agendamentoConsultaCompartilhadaValidator(getServerTimeNow())}
      decorators={agendamentoConsultaCompartilhadaDecorator}
    />
  )
}

const styles = {
  box: css`
    height: 27.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    ::-webkit-scrollbar {
      width: 0.3rem;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }
  `,
}
