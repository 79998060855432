/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, DropdownItem, HFlow, Icon, Theme, Tooltip } from 'bold-ui'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import theme from 'config/theme'
import { useEncerrarVideochamadaMutation } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { ReactComponent as SvgPictureInPicture } from 'images/videochamada/picture-in-picture-filled.svg'
import { Fragment, useCallback, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { SUPPORTS_PICTURE_IN_PICTIRE } from '../hooks/usePictureInPicture'
import { ConfiguracoesVideochamadaModel } from '../model-videochamada'
import { AcaoVideochamadaButton } from './AcaoVideochamadaButton'
import { CompartilharChamadaModal } from './CompartilharChamadaModal'
import { ConfiguracoesVideochamadaFormModal } from './ConfiguracoesVideochamadaFormModal'

type VideoChamadaFooterProps = {
  isOwner: boolean
  audioEnabled: boolean
  audioDeviceAvailable: boolean
  setAudioEnabled(newVal: boolean): void
  videoEnabled: boolean
  videoDeviceAvailable: boolean
  setVideoEnabled(newVal: boolean): void
  chatOpen: boolean
  setChatOpen(newVal: boolean): void
  videochamadaId: string
  onConfiguracoesChange(config: ConfiguracoesVideochamadaModel): void
  configuracoes: ConfiguracoesVideochamadaModel
  sharingScreen: boolean
  onShareScreenClick: () => void
  hasRemoteParticipant: boolean
  hasRemoteVideo: boolean
  pipActive: boolean
  setPipActive(newVal: boolean): void
  hasNewMessages: boolean
}

export function VideoChamadaFooter(props: VideoChamadaFooterProps) {
  const { analytics } = useFirebase()

  const {
    isOwner,
    audioDeviceAvailable,
    audioEnabled,
    setAudioEnabled,
    videoDeviceAvailable,
    videoEnabled,
    setVideoEnabled,
    videochamadaId,
    onConfiguracoesChange,
    configuracoes,
    chatOpen,
    setChatOpen,
    sharingScreen,
    onShareScreenClick,
    hasRemoteParticipant,
    hasRemoteVideo,
    pipActive,
    setPipActive,
    hasNewMessages,
  } = props

  const styles = createStyles(theme)

  const history = useHistory()
  const match = useRouteMatch()
  const handleRejection = useErrorHandler()
  const [encerrarVideoChamada] = useEncerrarVideochamadaMutation()

  const [isCompartilharChamadaModalOpen, setCompartilharChamadaModalOpen] = useState(false)
  const [isConfiguracoesModalOpen, setConfiguracoesModalOpen] = useState(false)

  const sairVideochamada = useCallback(() => history.push(`${match.url}/sair`), [history, match])

  const encerrarVideochamadaConfirm = useCallback(
    () =>
      confirm({
        title: `Encerrar a chamada ou apenas sair?`,
        body: 'Ao selecionar “Encerrar chamada”, a chamada será encerrada para todos os participantes.',
        type: 'danger',
        confirmLabel: 'Encerrar chamada',
        cancelLabel: 'Sair da chamada',
        onConfirm: () =>
          encerrarVideoChamada({ variables: { uuid: videochamadaId } })
            .then(() => {
              history.push('/videochamada/encerrada?owner=true')
            })
            .catch(handleRejection),
        onCancel: () => {
          sairVideochamada()
        },
      })(),
    [encerrarVideoChamada, videochamadaId, handleRejection, history, sairVideochamada]
  )

  const handleClickSairVideochamada = isOwner ? encerrarVideochamadaConfirm : sairVideochamada

  const pipDisabled = !SUPPORTS_PICTURE_IN_PICTIRE || !hasRemoteParticipant || !hasRemoteVideo

  const pipTooltip = !SUPPORTS_PICTURE_IN_PICTIRE
    ? 'Esse navegador não suporta o recurso picture-in-picture.'
    : !hasRemoteParticipant
    ? 'Picture-in-picture será habilitado quando outro participante entrar na chamada.'
    : !hasRemoteVideo
    ? 'Picture-in-picture será habilitado quando o outro participante ligar a câmera.'
    : ''

  return (
    <Fragment>
      <CompartilharChamadaModal
        open={isCompartilharChamadaModalOpen}
        onClose={() => setCompartilharChamadaModalOpen(false)}
      />
      <ConfiguracoesVideochamadaFormModal
        open={isConfiguracoesModalOpen}
        onClose={() => setConfiguracoesModalOpen(false)}
        initialConfigurations={configuracoes}
        onSubmit={onConfiguracoesChange}
      />
      <div css={styles.container}>
        <HFlow
          justifyContent='flex-start'
          style={css`
            flex: 5;
          `}
        ></HFlow>
        <HFlow
          justifyContent='center'
          alignItems='center'
          style={css`
            flex: 2;
          `}
        >
          <AcaoVideochamadaButton
            unavailable={!audioDeviceAvailable}
            state={audioDeviceAvailable && audioEnabled ? 'on' : 'off'}
            onClick={() => setAudioEnabled(!audioEnabled)}
            icon='mic'
          />
          <AcaoVideochamadaButton
            unavailable={!videoDeviceAvailable}
            state={videoDeviceAvailable && videoEnabled ? 'on' : 'off'}
            onClick={() => setVideoEnabled(!videoEnabled)}
            icon='video'
          />
          <AcaoVideochamadaButton state={sharingScreen ? 'on' : 'off'} onClick={onShareScreenClick} icon='screen' />

          <Tooltip text='Mais opções'>
            <DropdownButton style={styles.optionsButton} iconColor='white'>
              {isOwner && (
                <DropdownItem onClick={() => setCompartilharChamadaModalOpen(true)}>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Icon icon='link' />
                    Compartilhar chamada
                  </HFlow>
                </DropdownItem>
              )}
              <Tooltip text={pipTooltip}>
                <DropdownItem
                  disabled={pipDisabled}
                  onClick={() => {
                    if (!pipActive) analytics.logEvent('TELE_videochamadas_PiP_ativado')
                    setPipActive(!pipActive)
                  }}
                >
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <SvgPictureInPicture fill={pipDisabled ? theme.pallete.gray.c70 : theme.pallete.gray.c20} />
                    {pipActive ? 'Fechar picture-in-picture' : 'Iniciar picture-in-picture'}
                  </HFlow>
                </DropdownItem>
              </Tooltip>
              <DropdownItem onClick={() => setConfiguracoesModalOpen(true)}>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Icon icon='gearFilled' />
                  Configurações
                </HFlow>
              </DropdownItem>
            </DropdownButton>
          </Tooltip>

          <AcaoVideochamadaButton
            state={chatOpen ? 'on' : 'off'}
            onClick={() => setChatOpen(!chatOpen)}
            icon='chat'
            hasNotification={hasNewMessages}
          />
        </HFlow>
        <HFlow
          justifyContent='flex-end'
          style={css`
            flex: 5;
          `}
        >
          <Button kind='danger' size='small' style={styles.button} onClick={handleClickSairVideochamada}>
            <Icon icon='phoneFilled' color='white' style={styles.buttonIcon} />
            Sair da chamada
          </Button>
        </HFlow>
      </div>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    flex: 1;
    display: flex;
    justify-content: space-between;
  `,
  infoText: css`
    color: ${theme.pallete.gray.c100};
    font-size: ${theme.typography.sizes.button};
    width: 12.5rem;
    text-align: end;
    align-self: center;
    margin-right: 1rem;
  `,
  button: css`
    padding: 0.875rem 1rem;
    white-space: nowrap;
  `,
  buttonIcon: css`
    margin-right: 0.625rem;
  `,
  optionsButton: css`
    border: none;
    padding: 0.5rem;
    background-color: ${theme.pallete.gray.c30};
    &:not(:disabled):hover {
      background-color: ${theme.pallete.gray.c20};
    }
  `,
})
