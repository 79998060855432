import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { PageLoading } from 'components/loading'
import { useCuidadoCompartilhadoQuery } from 'graphql/hooks.generated'
import React from 'react'
import { Redirect, useParams } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

import { podeAcessarVisualizacaoCuidadoCompartilhado } from '../util-cuidadoCompartilhado'
import { VisualizarCuidadoCompartilhadoView } from './VisualizarCuidadoCompartilhadoView'

export const VisualizarCuidadoCompartilhadoRootView = () => {
  const { id } = useParams<{ id: string }>()
  const { acesso } = useAcessoLotacaoOrEstagio()

  const {
    data: { cuidadoCompartilhado },
    loading: isLoading,
  } = useCuidadoCompartilhadoQuery({
    variables: {
      id,
    },
  })

  if (isLoading) {
    return <PageLoading message='Carregando dados do cuidado compartilhado...' />
  } else if (isUndefinedOrNull(cuidadoCompartilhado)) {
    return <Redirect to='/404' />
  }

  const podeAcessarVisualizacaoCC = podeAcessarVisualizacaoCuidadoCompartilhado(acesso, cuidadoCompartilhado)

  if (!podeAcessarVisualizacaoCC) {
    return <Redirect to='/405' />
  } else {
    return <VisualizarCuidadoCompartilhadoView cuidadoCompartilhado={cuidadoCompartilhado} />
  }
}
