import { handleError } from 'components/error'

export const buildAgendamentosConflitantesErrorMessage = (apolloError) => {
  const errors = handleError({ error: apolloError, suppressNotificationError: true })
  return Object.keys(errors)
    .map((key) => errors[key])
    .join('. ')
}

export function lotacaoAgendamentoToString({
  nomeEquipe,
  ineEquipe,
  nomeProfissional,
  nomeCbo,
}: {
  nomeEquipe?: string
  ineEquipe?: string
  nomeProfissional: string
  nomeCbo: string
}): string {
  if (!!nomeProfissional) {
    const textoEquipe = !!nomeEquipe ? ` | ${nomeEquipe} - ${ineEquipe}` : ''

    return `${nomeProfissional} | ${nomeCbo.capitalize()}${textoEquipe}`
  } else {
    return ''
  }
}
