import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Box } from 'components/Box'
import { CheckboxField, DateField, Form, FormRenderProps, NumberField, SubmitButton, TimeField } from 'components/form'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { confirm } from 'components/modals/confirm'
import { css } from 'emotion'
import moment from 'moment'
import React, { useState } from 'react'
import { FormSpy } from 'react-final-form'

import { AtestadoAssinaturaDigitalView } from '../assinatura-digital/AtestadoAssinaturaDigitalView'
import { MESSAGE_TOOLTIP_SUBMIT_ATESTADO_DISABLED } from '../assinatura-digital/model-atestadoAssinaturaDigital'
import AtestadoModel, { CidadaoAtestado } from '../components/AtestadoModel'
import { cpfCnsDecorator, name } from './AtestadoPadraoForm'
import { getAtestadoPadraoText } from './AtestadoPadraoTextBox'
import { atestadoPadraoFormValidator } from './validator'

interface AtestadoPadraoEditModalProps {
  onSubmit?(values: AtestadoModel): void
  handleModalClose(): void
  cidadao: CidadaoAtestado
  initialValues: AtestadoModel
}

export default function AtestadoPadraoFormModal(props: AtestadoPadraoEditModalProps) {
  const { onSubmit, handleModalClose, initialValues, cidadao } = props
  const { acesso } = useAcessoLotacaoOrEstagio()
  const nomeUnidadeSaude = acesso?.unidadeSaude.nome
  const possuiCpfCns = cidadao?.cpf || cidadao?.cns
  const alert = useAlert()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [isEmailCidadaoDirty, setIsEmailCidadaoDirty] = useState(false)

  const handleSubmit = (values: AtestadoModel) => {
    onSubmit(values)
    alert('success', 'Atestado salvo com sucesso')
    handleModalClose()
  }

  const handleCancel = (formProps: FormRenderProps<AtestadoModel>) => {
    if (formProps.dirty || isEmailCidadaoDirty) {
      confirm({
        title: 'Deseja cancelar a edição?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar edição',
        confirmLabel: 'Sim, cancelar edição',
        onConfirm: () => {
          alert('success', 'Edição de atestado cancelado com sucesso.')
          handleModalClose()
        },
      })()
    } else {
      alert('success', 'Edição de atestado cancelado com sucesso.')
      handleModalClose()
    }
  }

  const renderForm = (formProps: FormRenderProps<AtestadoModel>) => {
    const values = resolveValue(formProps.values, name) || ({} as AtestadoModel)
    values.descricao = getAtestadoPadraoText({ cidadao, values, nomeUnidadeSaude })

    return (
      <Modal open onClose={() => handleCancel(formProps)}>
        <ModalBody>
          <Grid gap={1}>
            <Cell size={12}>
              <Heading level={1}>Editar atestado</Heading>
            </Cell>
            {!possuiCpfCns && (
              <>
                <Cell size={3}>
                  <NumberField
                    name={name.cpfCns}
                    label='CPF/CNS'
                    disabled={values.naoPossui}
                    required={!values.naoPossui}
                    maxLength={15}
                  />
                </Cell>
                <Cell size={9}>
                  <CheckboxField name={name.naoPossui} label='Não possui' style={styles.naoPossuiCheckbox} />
                </Cell>
              </>
            )}
            <Cell size={3}>
              <DateField
                name={name.data.absolutePath()}
                label='Data'
                required
                minDate={moment(initialValues.data).subtract(90, 'd').toDate()}
                maxDate={moment(initialValues.data).toDate()}
              />
            </Cell>
            <Cell size={2}>
              <TimeField name={name.hora} label='Hora' placeholder='hh:mm' required />
            </Cell>
            <Cell size={2}>
              <NumberField name={name.dias} label='Dias' required maxLength={3} />
            </Cell>
            <Cell size={5}>
              <CidSelectField name={name.cid10} label='CID10' renderGrupoCiapRelacionada={false} />
            </Cell>
            <AtestadoAssinaturaDigitalView
              cidadaoId={cidadao.id}
              onEmailCidadaoChange={setIsSubmitDisabled}
              hasCid10={!!formProps.values.cid10}
              style={styles.assinaturaDigital}
              onDirtyChange={setIsEmailCidadaoDirty}
            />
            <Cell size={12} />
          </Grid>
          <FormSpy>
            {({ values }) => (
              <Box>
                <VFlow vSpacing={1} style={styles.boxContainer}>
                  <Heading level={3}>ATESTADO</Heading>
                  <Text>{values.descricao}</Text>
                </VFlow>
              </Box>
            )}
          </FormSpy>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={() => handleCancel(formProps)}>Cancelar</Button>
            <Tooltip text={isSubmitDisabled && MESSAGE_TOOLTIP_SUBMIT_ATESTADO_DISABLED}>
              <SubmitButton
                title='Salvar'
                kind='primary'
                handleSubmit={formProps.handleSubmit}
                disabled={isSubmitDisabled}
              >
                Salvar
              </SubmitButton>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<AtestadoModel>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={atestadoPadraoFormValidator(possuiCpfCns, initialValues.data)}
      decorators={[cpfCnsDecorator]}
      render={renderForm}
      suppressNotificationError
    />
  )
}

const styles = {
  naoPossuiCheckbox: css`
    margin-top: 1.75rem;
  `,
  assinaturaDigital: css`
    padding-left: 1rem;
  `,
  boxContainer: css`
    justify-content: center;
    align-items: center;
    margin: 1rem 1.75rem;
  `,
}
