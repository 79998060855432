/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, FormControl, HFlow, Icon, Tooltip } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { Form, FormRenderProps, SubmitButton, TextField } from 'components/form'
import { HLabel } from 'components/HLabel'
import { useCidadaoEmailUpdateMutation } from 'graphql/hooks.generated'
import React, { Fragment, useEffect, useState } from 'react'
import { FormProps, FormSpy } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, email, required } from 'util/validation'

interface EmailUpdateFormModel {
  email: string
}

const validate = createValidator<EmailUpdateFormModel>({
  email: [required, email],
})

const path = metaPath<EmailUpdateFormModel>()

interface EmailUpdateFormProps extends Omit<FormProps<EmailUpdateFormModel>, 'onSubmit' | 'validate' | 'render'> {
  cidadaoId: ID
  cidadaoEmail: string
  onSubmitSuccess: (novoEmail: string) => void
  onEditarClick?: () => void
  disabled?: boolean
  onDirtyChange?: (dirty: boolean) => void
  canceled?: boolean
  onCancel?: (canceled: boolean) => void
}

export const EmailUpdateForm = React.memo((props: EmailUpdateFormProps) => {
  const {
    cidadaoId,
    cidadaoEmail,
    onSubmitSuccess,
    onEditarClick,
    canceled = false,
    onCancel,
    disabled = false,
    onDirtyChange,
    ...rest
  } = props

  const [isEditing, setIsEditing] = useState(false)
  const [updateEmailMutation] = useCidadaoEmailUpdateMutation()
  const handleRejection = useErrorHandler()

  useEffect(() => {
    if (canceled) {
      setIsEditing(false)
      onCancel?.(false)
    }
  }, [canceled, onCancel])

  const handleSubmitSuccess = (email: string) => {
    onSubmitSuccess(email)
    setIsEditing(false)
  }

  const handleEditarClick = () => {
    onEditarClick?.()
    setIsEditing(true)
  }

  const handleSubmitForm = ({ email }: EmailUpdateFormModel) =>
    updateEmailMutation({
      variables: {
        input: {
          cidadaoId,
          email,
        },
      },
    })
      .then(() => {
        handleSubmitSuccess(email)
      })
      .catch(handleRejection)

  const renderForm = ({ handleSubmit }: FormRenderProps<EmailUpdateFormModel>) => {
    return isEditing ? (
      <Fragment>
        <HFlow hSpacing={0.5}>
          <TextField lowercase name={path.email} label='E-mail' maxLength={50} disabled={disabled} />
          <FormControl label={<span>&nbsp;</span>}>
            <SubmitButton kind='primary' size='small' handleSubmit={handleSubmit} disabled={disabled}>
              Atualizar
            </SubmitButton>
          </FormControl>
        </HFlow>
        {onDirtyChange && (
          <FormSpy
            subscription={{ dirty: true }}
            onChange={(props) => {
              onDirtyChange?.(props.dirty)
            }}
          />
        )}
      </Fragment>
    ) : (
      <HFlow hSpacing={0.25} alignItems='center'>
        <HLabel title='E-mail:'>{cidadaoEmail}</HLabel>
        <Tooltip text='Editar'>
          <Button size='small' skin='ghost' onClick={handleEditarClick} disabled={disabled}>
            <Icon icon='penOutline' />
          </Button>
        </Tooltip>
      </HFlow>
    )
  }

  return (
    <Form<EmailUpdateFormModel>
      onSubmit={handleSubmitForm}
      validate={validate}
      render={renderForm}
      suppressNotificationError
      resetFormToInitialValues={true}
      {...rest}
    />
  )
})
