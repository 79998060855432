import { AtestadoPlanoInput, TipoAtestadoEnum } from 'graphql/types.generated'

import { AtestadoPlanoModel } from './AtestadosView'
import AtestadoModel from './components/AtestadoModel'
import { AtestadoQueryModel } from './model-atestado'

export const convertAtestadoPlanoFormToInput = (atestados: AtestadoPlanoModel): AtestadoPlanoInput => {
  const somenteNovos = atestados.atestadosRecentes?.filter((item) => !item.editingId)

  const emBranco = somenteNovos?.filter((it) => it.tipo === TipoAtestadoEnum.EM_BRANCO)
  const padrao = somenteNovos?.filter((it) => it.tipo === TipoAtestadoEnum.PADRAO)
  const licencaMaternidade = somenteNovos?.filter((it) => it.tipo === TipoAtestadoEnum.LICENCA_MATERNIDADE)

  return {
    emBranco: emBranco?.map((it) => ({ id: it.editingId, descricao: it.descricao })),
    licencaMaternidade: licencaMaternidade?.map((it) => ({
      id: it.editingId,
      descricao: it.descricao,
      cpfCns: it.cpfCns,
      naoPossui: it.naoPossui,
    })),
    padrao: padrao?.map((it) => ({
      id: it.editingId,
      descricao: it.descricao,
      cpfCns: it.cpfCns,
      naoPossui: it.naoPossui,
      date: it.data,
      hora: it.hora,
      cid10Id: it.cid10?.id,
      dias: it.dias,
    })),
  }
}

export const convertAtestadoQueryToForm = (item: AtestadoQueryModel): AtestadoModel => {
  const { id, descricao, tipo } = item

  return {
    editingId: id,
    descricao,
    tipo,
  }
}
