import { Button, Heading, Icon, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useErrorHandler } from 'components/error'
import { useFlags } from 'config/useFlagsContext'
import {
  useListaAtendimentoQuery,
  usePossuiAtendimentosNaoFinalizadosQuery,
  useTipoServicoCheckQuery,
} from 'graphql/hooks.generated'
import { PageParams } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import useAtmosphere from 'hooks/useAtmosphere'
import useLocalStorageState from 'hooks/useLocalStorageState'
import { useServerTime } from 'hooks/useServerTime'
import { useLocalStorage } from 'hooks/useStorage'
import { isEmpty } from 'lodash'
import qs from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import Permissions from 'types/Permissions'
import { InstantRange } from 'util/date/dateRange'

import AtendimentoListing from '../components/AtendimentoListing'
import { ExibindoSomenteNaoFinalizadosAlert } from './alerts/ExibindoSomenteNaoFinalizadosAlert'
import { ExistemAtendimentosNaoFinalizadosAlert } from './alerts/ExistemAtendimentosNaoFinalizadosAlert'
import { ListaAtendimentoFilter } from './filter/ListaAtendimentoFilter'
import { AtendimentosFilterModel, ListaAtendimentoFilterModel, ListaAtendimentoFilterPopperModel } from './filter/model'
import { useListaAtendimentoFilterDefault } from './filter/useListaAtendimentoFilterDefault'
import { convertListaAtendimentoFilterToInput, isFilterEqualDefaultFilter } from './filter/util'
import { useListaAtendimentosNaoFinalizadosAlert } from './hooks/useListaAtendimentosNaoFinalizadosAlert'
import { usePeriodoParaAtendimentosNaoFinalizados } from './hooks/usePeriodoParaAtendimentosNaoFinalizados'
import { useUpdateListaAtendimentoState } from './hooks/useUpdateListaAtendimentoState'
import { ListaAtendimentoFooter } from './ListaAtendimentoFooter'
import { ListaAtendimentoForm } from './ListaAtendimentoForm'
import { ListaAtendimentoItem } from './ListaAtendimentoItem'
import { partitionAtendimentos } from './util'

type AtendimentoTopicModel = { idUbs: number }

interface UrlParams {
  cidadaoId: string
}

export function ListaAtendimentosView() {
  const handleRejection = useErrorHandler()
  const location = useLocation()

  const { PILOTO_OBSERVACAO_1_ENABLED } = useFlags()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()
  const { getServerTimeNow } = useServerTime()

  const acessoId = acesso?.id

  const urlParams: UrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    parameterLimit: 5,
  })

  const { getFilterDefault } = useListaAtendimentoFilterDefault()
  const [filterDefault, setFilterDefault] = useState(getFilterDefault())

  const [state, setState] = useLocalStorageState<AtendimentosFilterModel>(
    `${acessoId}/lista-atendimento-filter`,
    filterDefault
  )

  const [backupFilterStorage, setBackupFilterStorage, deleteBackupFilterStorage] = useLocalStorage<
    ListaAtendimentoFilterPopperModel
  >(`${acessoId}/lista-atendimento-filter-backup`)

  const [isDefaultFilter, setIsDefaultFilter] = useState<boolean>(
    isFilterEqualDefaultFilter(state.filter, filterDefault.filter)
  )

  const [adicionarMode, setAdicionarMode] = useState(!!urlParams.cidadaoId)

  const {
    marcouAlertComoVisto,
    deveMostrarAlertNovamente,
    setAlertComoVisto,
  } = useListaAtendimentosNaoFinalizadosAlert(state.lastDailyUpdate)

  const periodoParaNaoFinalizados = usePeriodoParaAtendimentosNaoFinalizados(state.lastUpdate)

  const { data: possuiAtendimentosNaoFinalizados } = usePossuiAtendimentosNaoFinalizadosQuery({
    variables: {
      input: periodoParaNaoFinalizados,
    },
    skip: marcouAlertComoVisto && !deveMostrarAlertNovamente,
  })

  const { data, refetch } = useListaAtendimentoQuery({
    variables: {
      input: convertListaAtendimentoFilterToInput(state, periodoParaNaoFinalizados, filterDefault.filter.periodo),
    },
  })

  const { atendimentosEmObservacao, restAtendimentos } = partitionAtendimentos(data?.atendimentos?.content)

  const updateFilterDefault = useCallback(() => setFilterDefault(getFilterDefault()), [getFilterDefault])

  const refetchLista = useCallback(() => {
    updateFilterDefault()
    refetch()
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          lastUpdate: getServerTimeNow().getTime(),
        }))
      })
      .catch(handleRejection)
  }, [getServerTimeNow, handleRejection, refetch, setState, updateFilterDefault])

  useAtmosphere<AtendimentoTopicModel>({
    topic: `atendimento/${acesso?.unidadeSaude.id}`,
    onMessage: (data) => data?.idUbs && refetchLista(),
  })

  // utilizado para cache dos dados exibidos em TipoServicoCheckField.tsx
  useTipoServicoCheckQuery({
    fetchPolicy: 'cache-and-network',
    variables: { input: { pageParams: { sort: ['nome'], size: 100 }, unidadeSaudeSessao: true } },
  })

  const handleAdicionarCidadaoClick = () => {
    setAdicionarMode(!adicionarMode)
    analytics.logEvent('adicionar_cidadao_LA')
  }

  const hasFilterBackup = !isEmpty(backupFilterStorage)

  const updateBackupPeriodo = useCallback(
    (newPeriodo: InstantRange) => {
      if (hasFilterBackup) setBackupFilterStorage((prevValue) => ({ ...prevValue, periodo: newPeriodo }))
    },
    [hasFilterBackup, setBackupFilterStorage]
  )

  const onChangeFilter = useCallback(
    (filter: ListaAtendimentoFilterModel) => {
      updateFilterDefault()

      setState((prevState) => ({
        ...prevState,
        filter,
        pageParams: {
          ...prevState.pageParams,
          page: 0,
        },
        lastUpdate: getServerTimeNow().getTime(),
      }))
    },
    [getServerTimeNow, setState, updateFilterDefault]
  )

  const onChangeFooter = useCallback(
    (pageParams: PageParams) => {
      setState((prevState) => ({ ...prevState, pageParams }))
    },
    [setState]
  )

  const handleClear = useCallback(() => {
    deleteBackupFilterStorage()
    onChangeFilter(getFilterDefault().filter)
  }, [deleteBackupFilterStorage, getFilterDefault, onChangeFilter])

  const onClickSomenteAtendimentosNaoFinalizados = useCallback(() => {
    onChangeFilter({
      ...filterDefault.filter,
      isSomenteNaoFinalizados: true,
    })

    setAlertComoVisto()
  }, [filterDefault.filter, onChangeFilter, setAlertComoVisto])

  const resetFilterQuery = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        query: null,
      },
    }))
  }, [setState])

  useUpdateListaAtendimentoState({ state, setState, updateBackupPeriodo, updateFilterDefault })

  useEffect(() => {
    // roda quando a tela carrega a primeira vez
    setState((prevState) => ({ ...prevState, lastUpdate: getServerTimeNow().getTime() }))
    resetFilterQuery()
    deleteBackupFilterStorage()
  }, [resetFilterQuery, deleteBackupFilterStorage, setState, getServerTimeNow])

  useEffect(() => {
    setIsDefaultFilter(isFilterEqualDefaultFilter(state.filter, getFilterDefault().filter))
  }, [getFilterDefault, setIsDefaultFilter, state.filter])

  const hasAtendimentos = data?.atendimentos?.content?.length > 0

  const renderHeading = (
    <VFlow>
      {!!state?.filter?.isSomenteNaoFinalizados && <ExibindoSomenteNaoFinalizadosAlert onClear={handleClear} />}

      {possuiAtendimentosNaoFinalizados?.possuiAtendimentosNaoFinalizados &&
        !state?.filter?.isSomenteNaoFinalizados &&
        (!marcouAlertComoVisto || deveMostrarAlertNovamente) && (
          <ExistemAtendimentosNaoFinalizadosAlert
            onClickExibirSomenteNaoFinalizados={onClickSomenteAtendimentosNaoFinalizados}
            onClickManterFiltroAtual={setAlertComoVisto}
          />
        )}

      <CheckPermission permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}>
        <VFlow>
          <Button kind='primary' size='medium' onClick={handleAdicionarCidadaoClick}>
            <Icon icon={adicionarMode ? 'angleUp' : 'angleDown'} style={{ marginRight: '0.5rem' }} />
            {adicionarMode ? 'Cancelar adição' : 'Adicionar cidadão'}
          </Button>
          {adicionarMode && (
            <ListaAtendimentoForm cidadaoId={urlParams.cidadaoId} updateFilterDefault={updateFilterDefault} />
          )}
        </VFlow>
      </CheckPermission>
    </VFlow>
  )

  const renderFilter = (
    <ListaAtendimentoFilter
      filter={state.filter}
      filterBackup={backupFilterStorage}
      filterDefault={filterDefault.filter}
      isDefaultFilter={isDefaultFilter}
      onChangeFilter={onChangeFilter}
      updateFilterBackup={setBackupFilterStorage}
      deleteFilterBackup={() => deleteBackupFilterStorage()}
      onClear={handleClear}
    />
  )

  return (
    <AtendimentoListing
      title='Lista de atendimentos'
      heading={renderHeading}
      filter={renderFilter}
      footer={
        <ListaAtendimentoFooter
          pageInfo={data.atendimentos?.pageInfo}
          pageParams={state.pageParams}
          onChange={onChangeFooter}
        />
      }
    >
      {hasAtendimentos ? (
        <VFlow>
          {PILOTO_OBSERVACAO_1_ENABLED && atendimentosEmObservacao?.length > 0 && (
            <VFlow vSpacing={0.5}>
              <Heading level={5}>Cidadãos em observação</Heading>

              {atendimentosEmObservacao.map((atendimento) => (
                <ListaAtendimentoItem key={atendimento.id} atend={atendimento} />
              ))}
            </VFlow>
          )}

          {restAtendimentos?.length > 0 && (
            <VFlow vSpacing={0.5}>
              <Heading level={5}>Lista de atendimentos</Heading>

              {restAtendimentos.map((atendimento) => (
                <ListaAtendimentoItem key={atendimento.id} atend={atendimento} />
              ))}
            </VFlow>
          )}
        </VFlow>
      ) : (
        <div style={{ marginTop: '0.5rem' }}> Nenhum resultado encontrado.</div>
      )}
    </AtendimentoListing>
  )
}
