import ApolloClient from 'apollo-client'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { Calculation } from 'final-form-calculate'
import { IdadeGestacionalAcompanhamentoPreNatal, SituacaoProblema } from 'graphql/types.generated'
import { isEqual } from 'lodash'
import { isAfter, isAfterOrEqual, isBeforeOrEqual } from 'util/date/compare'
import { toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'
import { CiapCidPreNatal, meta, SoapState } from 'view/atendimentos/atendimento-individual/model'

import { Problema } from '../aside/types/ProblemaModel'
import { AvaliacaoFormModel } from '../avaliacao/AvaliacaoForm'
import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import {
  addProblemaCondicaoAutomatico,
  createProblemaCondicaoAutomaticoPreNatal,
  findProblemaComCiapW78,
  removeProblemaCondicao,
} from '../avaliacao/components/problemas-condicoes/utils/operations'
import {
  hasProblemaCondicaoComCiapW78,
  hasProblemaCondicaoDePreNatal,
} from '../avaliacao/components/problemas-condicoes/utils/verifications-problemasCondicoes'
import { ObjetivoFormModel } from '../objetivo'
import { FormAtivoObjetivoEnum } from '../objetivo/components/SwitchButtonObjetivoForm'
import { filterResultadosExamesAndConvertToInput, isResultadoExamePreNatal } from '../objetivo/resultados-exames/util'
import { getIdadeGestacional, getPreNatalCalculatorStatus } from './util-preNatal'

export const createPreNatalObjetivoCalculations = (
  objetivo: MetaPath<ObjetivoFormModel>,
  avaliacao: MetaPath<AvaliacaoFormModel>,
  isGestante: boolean,
  hasPermissionPreNatal: boolean
): Calculation[] => [
  {
    field: avaliacao.problemasECondicoes.absolutePath(),
    updates: {
      [objetivo.atendimentosEspecificos.formAtivo.absolutePath()]: (
        problemasCondicoes: ProblemaCondicaoModel[],
        allValues: SoapState
      ) => {
        const formAtivo = resolveValue(allValues, objetivo.atendimentosEspecificos.formAtivo)
        if (hasPermissionPreNatal) {
          return isGestante && hasProblemaCondicaoDePreNatal(problemasCondicoes, isGestante)
            ? FormAtivoObjetivoEnum.PRE_NATAL
            : formAtivo === FormAtivoObjetivoEnum.PRE_NATAL
            ? null
            : formAtivo
        } else {
          return formAtivo
        }
      },
    },
  },
]

export const createIdadeGestacionalCalculation = (
  metaIdadeGestacional: MetaPath<IdadeGestacionalAcompanhamentoPreNatal>,
  objetivo: MetaPath<ObjetivoFormModel>,
  avaliacao: MetaPath<AvaliacaoFormModel>,
  apollo: ApolloClient<object>,
  prontuarioId: ID,
  dataAtendimento: Instant,
  hasPermissionAcompanhamentoPreNatal: boolean,
  ultimaDumPreNatalAtivo: LocalDate,
  isGestante: boolean
): Calculation => ({
  field: [
    objetivo.resultadosExames.absolutePath(),
    objetivo.dum.absolutePath(),
    avaliacao.problemasECondicoes.absolutePath(),
  ],
  updates: {
    [metaIdadeGestacional.absolutePath()]: async (_, allValues: SoapState, prevValues: SoapState) => {
      const isAtendimentoPreNatal = hasProblemaCondicaoDePreNatal(allValues.avaliacao?.problemasECondicoes, isGestante)
      const dumAtendimentoAtual = allValues.objetivo?.dum
      const prevDumAtendimentoAtual = prevValues.objetivo?.dum

      const hasNoDum = !ultimaDumPreNatalAtivo && !dumAtendimentoAtual
      const skipCalculateIdadeGestacional = !(isGestante || isAtendimentoPreNatal) || hasNoDum

      if (!hasPermissionAcompanhamentoPreNatal || skipCalculateIdadeGestacional) return null

      const resultadosExames = allValues.objetivo?.resultadosExames
      const prevResultadosExames = prevValues.objetivo?.resultadosExames

      const resultadosExamesPreNatalAsInput =
        resultadosExames && filterResultadosExamesAndConvertToInput(resultadosExames, isResultadoExamePreNatal)
      const prevResultadosExamesPreNatalAsInput =
        prevResultadosExames && filterResultadosExamesAndConvertToInput(prevResultadosExames, isResultadoExamePreNatal)

      const hasResultadosExamesPreNatalChanged = !isEqual(
        resultadosExamesPreNatalAsInput,
        prevResultadosExamesPreNatalAsInput
      )

      const hasNewProblemaCondicaoPreNatal =
        isAtendimentoPreNatal && !hasProblemaCondicaoDePreNatal(prevValues.avaliacao?.problemasECondicoes, isGestante)
      const hasDumAtendimentoAtualChanged = !isEqual(dumAtendimentoAtual, prevDumAtendimentoAtual)

      const mustCalculateIdadeGestacional =
        hasDumAtendimentoAtualChanged || hasNewProblemaCondicaoPreNatal || hasResultadosExamesPreNatalChanged

      if (mustCalculateIdadeGestacional) {
        return await getIdadeGestacional(
          prontuarioId,
          dataAtendimento,
          apollo,
          resultadosExamesPreNatalAsInput,
          dumAtendimentoAtual ?? ultimaDumPreNatalAtivo
        )
      } else {
        return allValues.acompanhamentoPreNatal?.idadeGestacional
      }
    },
  },
})

export const createPreNatalAvaliacaoCalculations = (
  avaliacao: MetaPath<AvaliacaoFormModel>,
  isMedico: boolean,
  dataNascimentoCidadao: LocalDate,
  dataAtendimento: Instant,
  ciapCidPreNatal: CiapCidPreNatal,
  isContinuacaoPreNatal: boolean,
  hasPermissionPreNatal: boolean,
  problemasCidadao?: Problema[]
): Calculation[] =>
  hasPermissionPreNatal
    ? [
        createEncerrarGestacaoDataDesfechoCalculation(
          avaliacao,
          isMedico,
          dataNascimentoCidadao,
          dataAtendimento,
          ciapCidPreNatal,
          problemasCidadao
        ),
        createPreNatalProblemasCondicoesAnterioresCalculation(
          avaliacao,
          isMedico,
          dataNascimentoCidadao,
          dataAtendimento,
          ciapCidPreNatal,
          !isContinuacaoPreNatal,
          problemasCidadao
        ),
      ]
    : []

export const createPreNatalProblemasCondicoesAnterioresCalculation = (
  avaliacao: MetaPath<AvaliacaoFormModel>,
  isMedico: boolean,
  dataNascimentoCidadao: LocalDate,
  dataAtendimento: Instant,
  ciapCidPreNatal: CiapCidPreNatal,
  isCidadaSemGestacaoAtiva: boolean,
  problemasCidadao?: Problema[]
): Calculation => ({
  field: avaliacao.problemasECondicoes.absolutePath(),
  updates: {
    [meta.problemasECondicoes.absolutePath()]: (
      problemasCondicoesAvaliados: ProblemaCondicaoModel[],
      allValues: SoapState,
      prevValues: SoapState
    ) => {
      let problemasCondicoesAnteriores =
        resolveValue<ProblemaCondicaoModel[]>(allValues, meta.problemasECondicoes) ?? []

      const hasProblemaW78Avaliado = hasProblemaCondicaoComCiapW78(problemasCondicoesAvaliados)

      if (isCidadaSemGestacaoAtiva && !hasProblemaW78Avaliado) {
        const {
          mustRemoveW78NosProblemasCondicoesAnteriores,
          mustAddW78NosProblemasCondicoesAnteriores,
          dataInicioProblemaAltoRisco,
        } = getPreNatalCalculatorStatus(
          avaliacao,
          prevValues,
          problemasCondicoesAvaliados,
          problemasCondicoesAnteriores,
          problemasCidadao
        )

        if (mustRemoveW78NosProblemasCondicoesAnteriores) {
          problemasCondicoesAnteriores = removeProblemaCondicao(problemasCondicoesAnteriores, isMedico, {
            ciapToRemove: ciapCidPreNatal.ciap?.codigo,
            cidToRemove: ciapCidPreNatal.cid?.codigo,
          })
        }
        if (mustAddW78NosProblemasCondicoesAnteriores) {
          return addProblemaCondicaoAutomatico(
            problemasCondicoesAnteriores,
            createProblemaCondicaoAutomaticoPreNatal(
              ciapCidPreNatal,
              isMedico,
              SituacaoProblema.ATIVO,
              null,
              null,
              dataNascimentoCidadao,
              dataInicioProblemaAltoRisco,
              null,
              dataAtendimento
            )
          )
        }
      }

      return problemasCondicoesAnteriores
    },
  },
})

const createEncerrarGestacaoDataDesfechoCalculation = (
  avaliacao: MetaPath<AvaliacaoFormModel>,
  isMedico: boolean,
  dataNascimentoCidadao: LocalDate,
  dataAtendimento: Instant,
  ciapCidPreNatal: CiapCidPreNatal,
  problemasCidadao?: Problema[]
): Calculation => ({
  field: avaliacao.encerrarGestacao.dataDesfecho.absolutePath(),
  updates: {
    [meta.problemasECondicoes.absolutePath()]: (
      dataDesfecho: LocalDate,
      allValues: SoapState,
      prevValues: SoapState
    ) => {
      let listaProblemasCondicoesAnteriores =
        resolveValue<ProblemaCondicaoModel[]>(allValues, meta.problemasECondicoes) ?? []
      const dataDesfechoAnterior = resolveValue<string>(prevValues, meta.avaliacao.encerrarGestacao.dataDesfecho)

      const problemaPreNatalJaExistente = findProblemaComCiapW78(problemasCidadao)

      if (dataDesfecho !== dataDesfechoAnterior) {
        listaProblemasCondicoesAnteriores = handleApagarDesfecho(
          listaProblemasCondicoesAnteriores,
          isMedico,
          ciapCidPreNatal
        )
      }
      if (
        dataDesfecho &&
        isDataDesfechoValid(
          dataDesfecho,
          dataNascimentoCidadao,
          dataAtendimento,
          problemaPreNatalJaExistente?.ultimaEvolucao?.dataInicio
        )
      ) {
        return handleAdicionarDesfecho(
          listaProblemasCondicoesAnteriores,
          isMedico,
          dataNascimentoCidadao,
          dataDesfecho,
          ciapCidPreNatal,
          problemaPreNatalJaExistente
        )
      }
      return listaProblemasCondicoesAnteriores
    },
  },
})

const handleAdicionarDesfecho = (
  listaProblemasCondicoesAnteriores: ProblemaCondicaoModel[],
  isMedico: boolean,
  dataNascimentoCidadao: LocalDate,
  dataDesfecho: LocalDate,
  ciapCidPreNatal: CiapCidPreNatal,
  problemaPreNatalJaExistente: Problema
) =>
  addProblemaCondicaoAutomatico(
    listaProblemasCondicoesAnteriores,
    createProblemaCondicaoAutomaticoPreNatal(
      ciapCidPreNatal,
      isMedico,
      SituacaoProblema.RESOLVIDO,
      problemaPreNatalJaExistente?.cid10,
      problemaPreNatalJaExistente,
      dataNascimentoCidadao,
      null,
      dataDesfecho
    )
  )

const handleApagarDesfecho = (
  listaProblemasCondicoesAnteriores: ProblemaCondicaoModel[],
  isMedico: boolean,
  ciapCidPreNatal: CiapCidPreNatal
) =>
  removeProblemaCondicao(listaProblemasCondicoesAnteriores, isMedico, {
    ciapToRemove: ciapCidPreNatal.ciap?.codigo,
    situacaoToRemove: SituacaoProblema.RESOLVIDO,
    isAutomaticoToRemove: true,
  })

const isDataDesfechoValid = (
  dataDesfecho: string,
  dataNascimentoCidadao: string,
  dataAtendimento: Instant,
  dataInicioGestacao?: string
): boolean => {
  const dataDesfechoAsDate = toDate(dataDesfecho)
  return (
    isAfter(dataDesfecho, dataNascimentoCidadao) &&
    isBeforeOrEqual(dataDesfechoAsDate, dataAtendimento) &&
    ((dataInicioGestacao && isAfterOrEqual(dataDesfechoAsDate, toDate(dataInicioGestacao))) ?? true)
  )
}
