import { DateRange, Text, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PivotTable } from 'components/pivot-table/PivotTable'
import { useTreeBuilder } from 'components/pivot-table/useTreeBuilder'
import { useDataUltimoProcessamentoQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useCallback, useEffect, useState } from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'

import { RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO } from '../common/model'
import { RelatoriosGerenciaisTableWrapper } from '../components/RelatoriosGerenciaisTableWrapper'
import { cuidadoCompartilhadoKeyMapping } from './CuidadoCompartilhadoKeyMapping'
import { RelatorioGerencialCuidadoCompartilhadoModel } from './model-relatorioGerencilaCuidadoCompartilhado'

const OPERATIONS = {
  build: '/bi/cuidado-compartilhado/build',
  meta: '/bi/cuidado-compartilhado/meta',
}

const NAME = 'Relatório gerencial de cuidado compartilhado'

export function RelatorioGerencialCuidadoCompartilhadoView() {
  const { analytics } = useFirebase()

  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()
  const {
    meta,
    treeBuilderStatus: { isBuilding, isSearchingMeta },
    isMetaEmpty,
    setDateFilter,
    ...rest
  } = useTreeBuilder<RelatorioGerencialCuidadoCompartilhadoModel>({
    keyMapping: cuidadoCompartilhadoKeyMapping,
    operations: OPERATIONS,
  })

  const {
    data: { dataUltimoProcessamento },
  } = useDataUltimoProcessamentoQuery()

  const handleSubmit = useCallback(
    (dateRange: DateRange, periodUnit: string) => {
      setDateRangeFilter(dateRange)
      setDateFilter(dateRange, periodUnit)
    },
    [setDateFilter]
  )

  useEffect(() => {
    analytics.logEvent(RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO, {
      relatorio: NAME,
    })
  }, [analytics])

  const ultimoProcessamento = dataUltimoProcessamento
    ? `Último processamento em ${formatDateAndHoursMinutes(dataUltimoProcessamento)}`
    : 'Ainda não existem dados processados'

  return (
    <>
      <Breadcrumb title={NAME} />
      <PageHeaderSection title={NAME}>
        <Text>{ultimoProcessamento}</Text>
      </PageHeaderSection>
      <PageContent type='filled'>
        <VFlow>
          <RelatoriosGerenciaisTableWrapper loading={isSearchingMeta} isEmpty={isMetaEmpty} onSubmit={handleSubmit}>
            {meta && (
              <PivotTable<RelatorioGerencialCuidadoCompartilhadoModel>
                title={NAME}
                meta={meta}
                keyMapping={cuidadoCompartilhadoKeyMapping}
                dateRangeFilter={dateRangeFilter}
                isBuilding={isBuilding}
                {...rest}
              />
            )}
          </RelatoriosGerenciaisTableWrapper>
        </VFlow>
      </PageContent>
    </>
  )
}
