/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Checkbox, Icon, Tooltip } from 'bold-ui'
import { Fragment } from 'react'
import { isHttpsConnection } from 'util/https'
import { HTTPS_ASSINATURA_DIGITAL_MESSAGE } from 'view/configuracoes/instalacao/conexao/internet/assinatura-digital/model-assinaturaDigital'

interface AtestadoAssinaturaDigitalGerarCheckboxProps {
  checked?: boolean
  onChange: (checked: boolean) => void
  hasCid10?: boolean
}

export function AtestadoAssinaturaDigitalGerarCheckbox(props: AtestadoAssinaturaDigitalGerarCheckboxProps) {
  const { checked = false, onChange, hasCid10 = false } = props

  const disabled = !isHttpsConnection || hasCid10
  const tooltipCheckboxText: string = !isHttpsConnection
    ? HTTPS_ASSINATURA_DIGITAL_MESSAGE
    : hasCid10
    ? 'Atestados digitais não podem conter o CID10 do cidadão.'
    : null

  return (
    <Fragment>
      <Tooltip text={tooltipCheckboxText}>
        <div css={styles.wrapper}>
          <Checkbox
            style={styles.checkbox}
            label='Gerar e enviar atestado digital'
            checked={checked}
            onChange={() => onChange(!checked)}
            disabled={disabled}
          />
        </div>
      </Tooltip>
      <Tooltip text='O atestado digital será assinado eletrônicamente e enviado ao cidadão no e-mail cadastrado.'>
        <Icon icon='infoCircleFilled' fill='normal' size={1} style={styles.icon} />
      </Tooltip>
    </Fragment>
  )
}

const styles = {
  icon: css`
    margin: auto 0 auto -0.5rem;
  `,
  checkbox: css`
    margin: auto 0;
  `,
  wrapper: css`
    display: flex;
    align-items: flex-end;
  `,
}
