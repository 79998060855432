import { CidadaoAtendimentoSelectFieldModel } from 'components/form'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import createDecorator from 'final-form-calculate'
import { metaPath } from 'util/metaPath'

import { AgendamentoConsultaCompartilhadaModel, AgendamentoConsultaModel } from '../model-agenda'

const metaConsulta = metaPath<AgendamentoConsultaModel>()

const agendamentoConsultaCalculations = [
  {
    field: metaConsulta.cidadao.absolutePath(),
    updates: {
      [metaConsulta.contatoCidadao.email.absolutePath()]: (cidadaoValue: CidadaoAtendimentoSelectFieldModel) =>
        cidadaoValue?.contato.email,
      [metaConsulta.contatoCidadao.telefoneCelular.absolutePath()]: (
        cidadaoValue: CidadaoAtendimentoSelectFieldModel
      ) => cidadaoValue?.contato.telefoneCelular,
    },
  },
]

export const agendamentoConsultaDecorator = [createDecorator(...agendamentoConsultaCalculations)]

const metaConsultaCompartilhada = metaPath<AgendamentoConsultaCompartilhadaModel>()

const agendamentoConsultaCompartilhadaCalculations = [
  {
    field: metaConsultaCompartilhada.lotacaoConvidada.absolutePath(),
    updates: {
      [metaConsultaCompartilhada.email.absolutePath()]: (lotacaoValue: LotacaoAndEstagioSelectFieldModel) =>
        lotacaoValue?.profissional.email,
      [metaConsultaCompartilhada.telefone.absolutePath()]: (lotacaoValue: LotacaoAndEstagioSelectFieldModel) =>
        lotacaoValue?.profissional.telefone,
    },
  },
  {
    field: metaConsultaCompartilhada.cidadaoParticipante.cidadao.absolutePath(),
    updates: {
      [metaConsultaCompartilhada.cidadaoParticipante.contato.email.absolutePath()]: (
        cidadaoValue: CidadaoAtendimentoSelectFieldModel
      ) => cidadaoValue?.contato?.email,
      [metaConsultaCompartilhada.cidadaoParticipante.contato.telefoneCelular.absolutePath()]: (
        cidadaoValue: CidadaoAtendimentoSelectFieldModel
      ) => cidadaoValue?.contato?.telefoneCelular,
    },
  },
]

export const agendamentoConsultaCompartilhadaDecorator = [
  createDecorator(...agendamentoConsultaCompartilhadaCalculations),
]
