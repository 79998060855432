import { Cell, Grid } from 'bold-ui'
import { Box } from 'components/Box'
import { Form, FormRenderProps } from 'components/form'
import { SexoEnum, TipoAtendimentoProfissional } from 'graphql/types.generated'
import React, { useMemo, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import AtestadoModel, { CidadaoAtestado } from './components/AtestadoModel'
import {
  AtestadoModeloEnum,
  AtestadoSelectField,
  AtestadoSelectModel,
  items as modelosDefault,
} from './components/AtestadoSelectField'
import AtestadoEmBrancoForm from './em-branco/AtestadoEmBrancoForm'
import LicencaMaternidadeForm from './licenca-maternidade/LicencaMaternidadeForm'
import AtestadoModeloPrescricaoView from './modelo/AtestadoModeloPrescricaoView'
import AtestadoPadraoForm from './padrao/AtestadoPadraoForm'

export interface AtestadoCadastroProps {
  cidadao: CidadaoAtendimento
  atendimentoProfissional: AtendimentoProfissional
  handleSubmit: (values: AtestadoModel) => void
}

interface AtestadoCadastroModel {
  atestadoModelo: AtestadoSelectModel
}

const path = metaPath<AtestadoCadastroModel>()

export default function AtestadoCadastroView(props: AtestadoCadastroProps) {
  const { cidadao, atendimentoProfissional, handleSubmit } = props
  const [needRefetch, setNeedRefetch] = useState(false)

  const accessLicenca = useMemo(() => canAccessLicencaMaternidade(cidadao, atendimentoProfissional), [
    atendimentoProfissional,
    cidadao,
  ])

  const cidadaoAtestado = useMemo(() => convertCidadaoAtestado(cidadao), [cidadao])

  const renderForm = (formProps: FormRenderProps<AtestadoCadastroModel>) => {
    const atestadoModelo = formProps.values.atestadoModelo
    const handleUpdateDelete = (modelo: AtestadoSelectModel) => {
      formProps.form.change(path.atestadoModelo.absolutePath(), modelo)
      setNeedRefetch(true)
    }

    return (
      <Box>
        <Grid>
          <Cell size={4}>
            <AtestadoSelectField
              required
              label='Modelo'
              name={path.atestadoModelo}
              canAccessLicencaMaternidade={accessLicenca}
              needRefetch={needRefetch}
              onRefetch={() => setNeedRefetch(false)}
            />
          </Cell>
          {(!atestadoModelo || atestadoModelo?.tipo === AtestadoModeloEnum.PADRAO) && (
            <AtestadoPadraoForm
              onSubmit={handleSubmit}
              cidadao={cidadaoAtestado}
              dataAtendimento={atendimentoProfissional.iniciadoEm}
            />
          )}
          {atestadoModelo?.tipo === AtestadoModeloEnum.EM_BRANCO && (
            <Cell size={12}>
              <AtestadoEmBrancoForm onSubmit={handleSubmit} cidadaoId={cidadao.id} />
            </Cell>
          )}
          {atestadoModelo?.tipo === AtestadoModeloEnum.LICENCA_MATERNIDADE && (
            <LicencaMaternidadeForm
              onSubmit={handleSubmit}
              cidadao={cidadaoAtestado}
              dataAtendimento={atendimentoProfissional.iniciadoEm}
            />
          )}
          {atestadoModelo?.tipo === AtestadoModeloEnum.PERSONALIZADO && (
            <AtestadoModeloPrescricaoView
              onSubmit={handleSubmit}
              cidadao={cidadaoAtestado}
              dataAtendimento={atendimentoProfissional.iniciadoEm}
              idModelo={atestadoModelo?.id}
              onDelete={() => handleUpdateDelete(modelosDefault[0])}
              onUpdate={(nomeModelo) =>
                handleUpdateDelete({
                  ...atestadoModelo,
                  nome: nomeModelo,
                })
              }
            />
          )}
        </Grid>
      </Box>
    )
  }

  return <Form<AtestadoCadastroModel> render={renderForm} />
}

const convertCidadaoAtestado = (cidadao: CidadaoAtendimento) => {
  return {
    id: cidadao.id,
    nome: cidadao.nome,
    nomeSocial: cidadao.nomeSocial,
    cpf: cidadao.cpf,
    cns: cidadao.cns,
  } as CidadaoAtestado
}

const canAccessLicencaMaternidade = (cidadao: CidadaoAtendimento, atendimentoProfissional: AtendimentoProfissional) => {
  if (cidadao?.idadeEmAnos < 5) return false

  if (cidadao?.identidadeGeneroDbEnum === null && cidadao?.sexo !== SexoEnum.FEMININO) return false

  if (atendimentoProfissional?.tipo === TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO) return false

  return true
}
