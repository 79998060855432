import { Grid, Text } from 'bold-ui'
import { FilterItem } from 'components/filteritem'
import { isSameDay } from 'date-fns'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsDdMmYyyy } from 'util/date/formatDate'
import { HOJE } from 'util/date/humanize-date'
import {
  ListaCuidadoCompartilhadoFilterModel,
  StatusCuidadoRecord,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

interface ListaCuidadoCompartilhadoFilterTagsProps {
  filters: ListaCuidadoCompartilhadoFilterModel
}

export const ListaCuidadoCompartilhadoFilterTags = (props: ListaCuidadoCompartilhadoFilterTagsProps) => {
  const { filters } = props

  const { getServerTimeNow } = useServerTime()

  const now = getServerTimeNow()
  const { status, periodo, profissionalExecutante, profissionalSolicitante } = filters

  const isStatusFiltered = status?.isNotEmpty()
  const isSolicitanteFiltered = !isUndefinedOrNull(profissionalSolicitante)
  const isExecutanteFiltered = !isUndefinedOrNull(profissionalExecutante)
  const isPeriodoFiltered = !isUndefinedOrNull(periodo?.startDate) && !isUndefinedOrNull(periodo?.endDate)
  const hasFilters = isStatusFiltered || isPeriodoFiltered || isSolicitanteFiltered || isExecutanteFiltered

  const isPeriodoStartAndEndDateSameDayAndAreToday =
    isPeriodoFiltered && isSameDay(periodo?.startDate, periodo?.endDate) && isSameDay(periodo?.startDate, now)

  const statusText = isStatusFiltered && status.map((item) => StatusCuidadoRecord[item].descricao).join(', ')
  const startDateText = isSameDay(periodo?.startDate, now) ? HOJE : dateAsDdMmYyyy(periodo?.startDate)
  const endDateText = isSameDay(periodo?.endDate, now) ? HOJE : dateAsDdMmYyyy(periodo?.endDate)
  const periodoText = isPeriodoStartAndEndDateSameDayAndAreToday
    ? HOJE
    : `${startDateText} até ${endDateText}`.capitalize()

  return hasFilters ? (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      {isStatusFiltered && <FilterItem label='Status:' value={statusText} />}
      {isPeriodoFiltered && <FilterItem label='Período:' value={periodoText} />}
      {isSolicitanteFiltered && (
        <FilterItem label='Profissional solicitante:' value={profissionalSolicitante.profissional.nome} />
      )}
      {isExecutanteFiltered && (
        <FilterItem label='Profissional executante:' value={profissionalExecutante.profissional.nome} />
      )}
    </Grid>
  ) : (
    <Text>Sem filtros ativos</Text>
  )
}
