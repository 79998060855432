import { SelectHelperMenuItem, useLocale, VFlow } from 'bold-ui'
import { Text } from 'bold-ui'
import { SelectField } from 'components/form'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_SIZE,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { LotacaoAndEstagioSelectFieldDocument } from 'graphql/hooks.generated'
import {
  Cbo,
  Equipe,
  Estagio,
  Lotacao,
  LotacaoAndEstagioSelectFieldQuery,
  LotacaoAndEstagioSelectFieldQueryVariables,
  Profissional,
  UnidadeSaude,
} from 'graphql/types.generated'
import React from 'react'

export type LotacaoAndEstagioSelectFieldModel = {
  __typename?: string
  id: string
  profissional: Pick<Profissional, 'id' | 'nome' | 'email' | 'telefone'>
  unidadeSaude?: Pick<UnidadeSaude, 'nome' | 'cnes'>
  lotacaoSupervisora?: { profissional: { id: string; nome: string } }
  cbo?: Cbo
  equipe?: Pick<Equipe, 'id' | 'nome' | 'ine' | 'isAD'>
}

export interface LotacaoAndEstagioSelectFieldProps extends AsyncSelectFieldProps<LotacaoAndEstagioSelectFieldModel> {
  unidadeSaudeId?: string
  equipeId?: string
  cboId?: string
  excludeProfissionaisIds?: ID[]
  loadItemsOnOpen?: boolean
  possuiAgendaCadastrada?: boolean
  nivelSuperior?: boolean
  includeEstagios?: boolean
  semEquipe?: boolean
  canWriteAtendimento?: boolean
  canWriteRegistroTardio?: boolean
  apenasEquipeCuidadoCompartilhado?: boolean
  equipeVinculadaId?: ID
}

LotacaoAndEstagioSelectField.defaultProps = {
  loadItemsOnOpen: true,
} as Partial<LotacaoAndEstagioSelectFieldProps>

export function LotacaoAndEstagioSelectField(props: LotacaoAndEstagioSelectFieldProps) {
  const {
    loadItemsOnOpen,
    unidadeSaudeId,
    nivelSuperior,
    includeEstagios = false,
    equipeId,
    excludeProfissionaisIds,
    possuiAgendaCadastrada,
    semEquipe,
    canWriteRegistroTardio = false,
    canWriteAtendimento = !canWriteRegistroTardio,
    apenasEquipeCuidadoCompartilhado,
    equipeVinculadaId,
    cboId,
    ...rest
  } = props

  const locale = useLocale()

  const { skipping, selectProps } = useAsyncQuerySelect<
    LotacaoAndEstagioSelectFieldModel,
    LotacaoAndEstagioSelectFieldQuery,
    LotacaoAndEstagioSelectFieldQueryVariables
  >({
    query: LotacaoAndEstagioSelectFieldDocument,
    extractItems: (data) => data?.lotacoesAndEstagios.map(convertToModel),
    variables: (inputString) => ({
      input: {
        query: inputString,
        unidadeSaude: unidadeSaudeId,
        cbo: cboId,
        equipe: equipeId,
        ativo: true,
        includeEstagios,
        nivelSuperior,
        semEquipe,
        canWriteAtendimento,
        canWriteRegistroTardio,
        excludeProfissionaisIds,
        possuiAgendaCadastrada,
        apenasEquipeCuidadoCompartilhado,
        equipeVinculadaId,
        maxResults: DEFAULT_SELECT_SIZE,
      },
    }),
    skip: (inputString) => (!loadItemsOnOpen ? inputString?.trim()?.length < 2 : undefined),
    debounceTime: 500,
  })

  const renderItem = (item: LotacaoAndEstagioSelectFieldModel) => {
    switch (item.__typename) {
      case 'Lotacao':
        return renderLotacao(item as Lotacao, unidadeSaudeId)
      case 'Estagio':
        return renderEstagio(item as Estagio, unidadeSaudeId)
    }
  }

  return (
    <SelectField<LotacaoAndEstagioSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      components={
        !loadItemsOnOpen && {
          EmptyItem: () => (
            <SelectHelperMenuItem>
              {skipping ? 'Digite para buscar um profissional.' : locale.select.emptyItem}
            </SelectHelperMenuItem>
          ),
        }
      }
      {...selectProps}
      {...rest}
    />
  )
}

const itemToString = (item: LotacaoAndEstagioSelectFieldModel) => item && item.profissional.nome

const renderLotacao = (item: Lotacao, unidadeSaudeId: string) => {
  return (
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'>
        {item.profissional.nome} - <Cpf value={item.profissional.cpf} />
      </Text>
      <HLabel title='CBO '>{`${item.cbo.nome} - ${item.cbo.cbo2002}`}</HLabel>
      <HLabel title='INE '> {(item.equipe && `${item.equipe.nome} - ${item.equipe.ine}`) || 'Sem equipe'}</HLabel>
      {!unidadeSaudeId && (
        <HLabel title='Unidade de saúde '>
          {item.unidadeSaude.nome} - {item.unidadeSaude.cnes}
        </HLabel>
      )}
    </VFlow>
  )
}

const renderEstagio = (item: Estagio, unidadeSaudeId: string) => {
  return (
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'>
        {item.profissional.nome} - <Cpf value={item.profissional.cpf} />
      </Text>
      <HLabel title='Supervisor '>{item.lotacaoSupervisora?.profissional.nome}</HLabel>
      <HLabel title='Permissões '>{`${item.cbo.nome} - ${item.cbo.cbo2002} | ${
        (item.equipe && `${item.equipe.nome} - ${item.equipe.ine}`) || 'Sem equipe'
      }`}</HLabel>
      {!unidadeSaudeId && (
        <HLabel title='Unidade de saúde '>
          {item.unidadeSaude.nome} - {item.unidadeSaude.cnes}
        </HLabel>
      )}
    </VFlow>
  )
}

const convertToModel = (
  lotacaoAndEstagio: LotacaoAndEstagioSelectFieldQuery['lotacoesAndEstagios'][0]
): LotacaoAndEstagioSelectFieldModel =>
  (lotacaoAndEstagio.__typename === 'Estagio' || lotacaoAndEstagio.__typename === 'Lotacao') && {
    __typename: lotacaoAndEstagio.__typename,
    lotacaoSupervisora: lotacaoAndEstagio.__typename === 'Estagio' && lotacaoAndEstagio.lotacaoSupervisora,
    id: lotacaoAndEstagio.id,
    cbo: lotacaoAndEstagio.cbo,
    equipe: lotacaoAndEstagio.equipe
      ? {
          id: lotacaoAndEstagio.equipe.id,
          ine: lotacaoAndEstagio.equipe.ine,
          nome: lotacaoAndEstagio.equipe.nome,
          isAD: lotacaoAndEstagio.__typename === 'Estagio' ? false : !!lotacaoAndEstagio.equipe.isAD,
        }
      : undefined,
    profissional: lotacaoAndEstagio.profissional,
    unidadeSaude: lotacaoAndEstagio.unidadeSaude,
  }
