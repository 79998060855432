import { PivotTableProps } from 'components/pivot-table/PivotTable'

import { KeyMapping, keyMappings } from '../common/KeyMapping'
import { RelatorioGerencialCuidadoCompartilhadoModel } from './model-relatorioGerencilaCuidadoCompartilhado'

const conduta: KeyMapping = {
  keyName: 'Conduta',
  formatter: (s: string) => s.capitalize(),
}
const prioridade: KeyMapping = {
  keyName: 'Prioridade',
  formatter: (s: string) => s.capitalize(),
}

export const cuidadoCompartilhadoKeyMapping: PivotTableProps<
  RelatorioGerencialCuidadoCompartilhadoModel
>['keyMapping'] = new Map<keyof RelatorioGerencialCuidadoCompartilhadoModel, KeyMapping>([
  ['conduta', conduta],
  ['prioridade', prioridade],
  ['turno', keyMappings.turno],
  ['mes', keyMappings.mes],
  ['dia', keyMappings.dia],
  ['diaDaSemana', keyMappings.diaDaSemana],
  ['hora', keyMappings.hora],
  ['semana', keyMappings.semana],
  ['sexo', keyMappings.sexo],
  ['faixaEtaria', keyMappings.faixaEtaria],
])
