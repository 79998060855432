import { conditionalRequired, createValidator, maxLength, required } from 'util/validation'

import { GarantiaAcessoEdicaoModel, GarantiaAcessoFormModel } from './model-garantiaAcesso'

export const garantiaAcessoValidator = (isCEO: boolean) =>
  createValidator<GarantiaAcessoFormModel>({
    cidadao: [required],
    motivo: [required, maxLength(200)],
    tipoServico: [conditionalRequired(isCEO)],
    tipoAtendimento: [conditionalRequired(!isCEO)],
  })

export const editarCidadaoGarantiaAcessoValidator = (isCEO: boolean) =>
  createValidator<GarantiaAcessoEdicaoModel>({
    motivo: [required, maxLength(200)],
    tipoServico: [conditionalRequired(isCEO)],
    tipoAtendimento: [conditionalRequired(!isCEO)],
  })
