import { Button, HFlow, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormRenderProps, RichTextField, SubmitButton } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import React, { useState } from 'react'
import { metaPath } from 'util/metaPath'

import { AtestadoAssinaturaDigitalView } from '../assinatura-digital/AtestadoAssinaturaDigitalView'
import { MESSAGE_TOOLTIP_SUBMIT_ATESTADO_DISABLED } from '../assinatura-digital/model-atestadoAssinaturaDigital'
import AtestadoModel from '../components/AtestadoModel'
import { atestadoEmBrancoFormValidator } from './validator'

export interface AtestadoEmBrancoFormProps {
  initialValues?: AtestadoModel
  onSubmit(values: AtestadoModel): void
  isDirtForm?(): void
  cidadaoId: ID
}

export const name = metaPath<AtestadoModel>()

export default function AtestadoEmBrancoForm(props: AtestadoEmBrancoFormProps) {
  const { initialValues, onSubmit, isDirtForm, cidadaoId } = props
  const alert = useAlert()

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [isEmailCidadaoDirty, setIsEmailCidadaoDirty] = useState(false)
  const [isEmailCidadaoCanceled, setIsEmailCidadaoCanceled] = useState(false)

  const handleSubmit = (values: AtestadoModel, formApi: FormApi) => {
    onSubmit({ ...values, tipo: TipoAtestadoEnum.EM_BRANCO })
    setTimeout(formApi.reset)
    formApi.getRegisteredFields().forEach((item) => formApi.resetFieldState(item))
    alert('success', 'Atestado salvo com sucesso.')
  }

  const handleCancel = (formProps: FormRenderProps<AtestadoModel>) => {
    if (formProps.dirty || isEmailCidadaoDirty) {
      confirm({
        title: 'Deseja cancelar a inclusão?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar inclusão',
        confirmLabel: 'Sim, cancelar inclusão',
        onConfirm: () => {
          alert('success', 'Cadastro de atestado cancelado com sucesso.')
          setIsEmailCidadaoCanceled(true)
          formProps.form.reset()
        },
      })()
    } else {
      alert('success', 'Cadastro de atestado cancelado com sucesso.')
      setIsEmailCidadaoCanceled(true)
    }
    formProps.form.getRegisteredFields().forEach((item) => formProps.form.resetFieldState(item))
  }

  const renderForm = (formProps: FormRenderProps<AtestadoModel>) => {
    return (
      <VFlow>
        <AtestadoAssinaturaDigitalView
          cidadaoId={cidadaoId}
          onEmailCidadaoChange={setIsSubmitDisabled}
          hasCid10={!!formProps.values.cid10}
          onDirtyChange={setIsEmailCidadaoDirty}
          canceled={isEmailCidadaoCanceled}
          onCancel={setIsEmailCidadaoCanceled}
        />
        <RichTextField
          name={name.descricao}
          maxLength={4000}
          placeholder='Insira conteúdo do atestado'
          height={7}
          label='Conteúdo'
          required
          onChange={isDirtForm}
        />

        <HFlow justifyContent='flex-end'>
          <Button size='small' onClick={() => handleCancel(formProps)}>
            Cancelar
          </Button>
          <Tooltip text={isSubmitDisabled && MESSAGE_TOOLTIP_SUBMIT_ATESTADO_DISABLED}>
            <SubmitButton
              type='button'
              size='small'
              kind='primary'
              handleSubmit={formProps.handleSubmit}
              throttleSubmit
              disabled={isSubmitDisabled}
            >
              Salvar atestado
            </SubmitButton>
          </Tooltip>
        </HFlow>
      </VFlow>
    )
  }

  return (
    <Form<AtestadoModel>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleSubmit}
      validate={atestadoEmBrancoFormValidator}
    />
  )
}
