/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, Tabs, VFlow } from 'bold-ui'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { PageLoading } from 'components/loading'
import { TabLink } from 'components/route'
import { useAcessoHasVinculacaoEquipeNasfQuery } from 'graphql/hooks.generated'
import { Fragment } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'

import { ResponsavelCuidadoCompartilhado } from '../model-cuidadoCompartilhado'
import { ListaCuidadoCompartilhadoExecutanteView } from './ListaCuidadoCompartilhadoExecutanteView'
import { ListaCuidadoCompartilhadoSolicitanteView } from './ListaCuidadoCompartilhadoSolicitanteView'

export const LCC_TABS_ROUTE: Record<ResponsavelCuidadoCompartilhado, string> = {
  EXECUTANTE: 'compartilhamentos',
  SOLICITANTE: 'solicitacoes',
}

export const ListaCuidadoCompartilhadoRootView = () => {
  const match = useRouteMatch()

  const {
    data: { acessoHasVinculacaoEquipeNasf },
    loading,
  } = useAcessoHasVinculacaoEquipeNasfQuery()

  const defaultTabOpen = acessoHasVinculacaoEquipeNasf ? LCC_TABS_ROUTE['SOLICITANTE'] : LCC_TABS_ROUTE['EXECUTANTE']

  return loading ? (
    <PageLoading message='Carregando...' />
  ) : (
    <Fragment>
      <PageContainer fluid style={styles.pageContainer}>
        <VFlow>
          <Heading level={1}>Cuidados compartilhados</Heading>
          <Tabs>
            <TabLink to={`${match.url}/${LCC_TABS_ROUTE['SOLICITANTE']}`}>Solicitados por mim</TabLink>
            <TabLink to={`${match.url}/${LCC_TABS_ROUTE['EXECUTANTE']}`}>Compartilhados comigo</TabLink>
          </Tabs>
        </VFlow>
      </PageContainer>
      <PageContent fluid type='transparent'>
        <Switch>
          <Route
            exact
            path={`${match.url}/${LCC_TABS_ROUTE['EXECUTANTE']}`}
            component={ListaCuidadoCompartilhadoExecutanteView}
          />
          <Route
            exact
            path={`${match.url}/${LCC_TABS_ROUTE['SOLICITANTE']}`}
            component={ListaCuidadoCompartilhadoSolicitanteView}
          />
          <Redirect path={`${match.url}`} to={`${match.url}/${defaultTabOpen}`} />
        </Switch>
      </PageContent>
    </Fragment>
  )
}

const styles = {
  pageContainer: css`
    padding: 1rem 2rem 0rem 2rem;
  `,
}
