import { useResponderSolicitacaoEntradaVideochamadaMutation } from 'graphql/hooks.generated'
import { RespostaSolicitacaoEntradaVideochamadaEnum } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { useOnBeforeUnload } from 'hooks/useOnBeforeUnload'
import { useCallback, useRef, useState } from 'react'
import React from 'react'

import {
  SolicitacaoEntradaVideochamadaAtmosphereResponse,
  StatusSolicitacaoEntradaVideochamadaEnum,
} from '../model-videochamada'
import { SolicitacaoEntrarVideochamadaConfirmModal } from './SolicitacaoEntrarVideochamadaConfirmModal'
import { SolicitacaoEntrarVideochamadaTimeoutModal } from './SolicitacaoEntrarVideochamadaTimeoutModal'

const SOLICITACAO_TIMEOUT_LIMIT = 5 * 60 * 1000 // milisec
const MAX_PARTICIPANTES = 2

interface SolicitacaoEntradaVideochamada {
  nomeSolicitante: string
  status: StatusSolicitacaoEntradaVideochamadaEnum
  participanteId: ID
}

interface SolicitacaoEntrarVideochamadaListenerProps {
  videochamadaId: ID
  numeroParticipantes: number
}

export function SolicitacaoEntrarVideochamadaListener(props: SolicitacaoEntrarVideochamadaListenerProps) {
  const { videochamadaId, numeroParticipantes } = props

  const [solicitacaoEntrada, setSolicitacaoEntrada] = useState<SolicitacaoEntradaVideochamada>()
  const isAguardandoResposta =
    solicitacaoEntrada?.status === StatusSolicitacaoEntradaVideochamadaEnum.AGUARDANDO_RESPOSTA
  const timeout = useRef<NodeJS.Timeout>()

  const [responderEntradaVideochamada] = useResponderSolicitacaoEntradaVideochamadaMutation()

  const handleSolicitacao = useCallback(
    (response: SolicitacaoEntradaVideochamadaAtmosphereResponse) => {
      if (response?.cancelar && solicitacaoEntrada) {
        if (solicitacaoEntrada.participanteId === response.participanteId) setSolicitacaoEntrada(null)
      }
      if (numeroParticipantes + Number(isAguardandoResposta) >= MAX_PARTICIPANTES) {
        responderEntradaVideochamada({
          variables: {
            input: {
              videochamadaId,
              participanteId: response.participanteId,
              resposta: RespostaSolicitacaoEntradaVideochamadaEnum.VIDEOCHAMADA_LOTADA,
            },
          },
        })
      } else {
        const { participanteId, nomeParticipante } = response

        setSolicitacaoEntrada({
          nomeSolicitante: nomeParticipante,
          status: StatusSolicitacaoEntradaVideochamadaEnum.AGUARDANDO_RESPOSTA,
          participanteId,
        })

        timeout.current = setTimeout(() => {
          setSolicitacaoEntrada({
            nomeSolicitante: nomeParticipante,
            status: StatusSolicitacaoEntradaVideochamadaEnum.TIMEOUT,
            participanteId,
          })

          responderEntradaVideochamada({
            variables: {
              input: {
                videochamadaId,
                participanteId,
                resposta: RespostaSolicitacaoEntradaVideochamadaEnum.TIMEOUT,
              },
            },
          })
        }, SOLICITACAO_TIMEOUT_LIMIT)
      }
    },
    [isAguardandoResposta, numeroParticipantes, responderEntradaVideochamada, solicitacaoEntrada, videochamadaId]
  )

  useAtmosphere<SolicitacaoEntradaVideochamadaAtmosphereResponse>({
    topic: `public/videochamada/${videochamadaId}/solicitacoesEntrada`,
    onMessage: handleSolicitacao,
  })

  const responderSolicitacao = useCallback(
    async (aceita: boolean, keepalive = false) => {
      timeout.current && clearTimeout(timeout.current)
      timeout.current = null

      if (solicitacaoEntrada)
        await responderEntradaVideochamada({
          variables: {
            input: {
              videochamadaId,
              participanteId: solicitacaoEntrada.participanteId,
              resposta: aceita
                ? RespostaSolicitacaoEntradaVideochamadaEnum.ACEITA
                : RespostaSolicitacaoEntradaVideochamadaEnum.NEGADA,
            },
          },
          context: {
            fetchOptions: {
              keepalive: keepalive,
            },
          },
        })
      setSolicitacaoEntrada(null)
    },
    [responderEntradaVideochamada, solicitacaoEntrada, videochamadaId]
  )

  const handleClose = () => responderSolicitacao(false)
  const handleBeforeUnload = useCallback(() => responderSolicitacao(false, true), [responderSolicitacao])

  useOnBeforeUnload(handleBeforeUnload)

  switch (solicitacaoEntrada?.status) {
    case StatusSolicitacaoEntradaVideochamadaEnum.AGUARDANDO_RESPOSTA:
      return (
        <SolicitacaoEntrarVideochamadaConfirmModal
          nomeSolicitante={solicitacaoEntrada.nomeSolicitante}
          onResponse={responderSolicitacao}
          onClose={handleClose}
        />
      )
    case StatusSolicitacaoEntradaVideochamadaEnum.TIMEOUT:
      return (
        <SolicitacaoEntrarVideochamadaTimeoutModal
          nomeSolicitante={solicitacaoEntrada.nomeSolicitante}
          timeoutLimit={SOLICITACAO_TIMEOUT_LIMIT}
          onClose={handleClose}
        />
      )
    default:
      return null
  }
}
